<div class="surface-ground relative h-full overflow-auto p-2">
  <div class="linear max mx-auto mb-24 transition-all duration-500">
    <section class="rounded-md px-2">
      <div class="linear max mx-auto transition-all duration-500">
        <div class="shipping-item relative flex shrink grow p-[4px]">
          <div
            class="absolute w-full flex-1 opacity-100 transition-[opacity] transition-[transform] duration-300 md:static"
            [ngStyle]="{
              transform: !showSubscriber
                ? 'translateX(0%) translateZ(1px)'
                : 'translateX(-106%) translateZ(1px)',
            }"
          >
            <h2 class="h-level-2 mb-2">
              <span>Newsletter</span>
              <p-button
                class="contents"
                label="(Create)"
                styleClass="ml-3 mp-button"
                [text]="true"
                (click)="createNewsletterVisible = !0"
              ></p-button>
            </h2>
            <p-card
              class="mx-auto block md:mx-0 md:my-auto"
              styleClass="padding-clear carpool-list shadow-none rounded-tr-none rounded-br-none"
            >
              <ul role="list">
                <li
                  *ngFor="let category of categories"
                  (click)="onClickNewsLetter()"
                  class="h-level-3 flex cursor-pointer justify-between px-2 py-3"
                >
                  <span>{{ category.name }}</span>
                  <span class="material-icons">chevron_right</span>
                </li>
              </ul>
            </p-card>
          </div>
          <div class="hidden flex-1 opacity-100 md:block">
            <h2 class="h-level-2 mb-2">Subscribers</h2>
            <p-card
              class="mx-auto block md:mx-0 md:my-auto"
              styleClass="padding-clear carpool-list shadow-none rounded-tl-none rounded-bl-none"
            >
              <ul role="list">
                <li
                  class="h-level-3 flex cursor-pointer justify-between px-2 py-3"
                  *ngFor="let subscriber of newsletterSubscribers"
                >
                  <span>{{ subscriber.email }}</span>
                </li>
              </ul>
            </p-card>
          </div>
          <div
            class="absolute block w-[380px] flex-1 opacity-100 transition-[opacity] transition-[transform] duration-300 md:hidden"
            [ngStyle]="{
              transform: showSubscriber
                ? 'translateX(0%) translateZ(1px)'
                : 'translateX(110%) translateZ(1px)',
            }"
          >
            <h2 class="h-level-2 mb-2">Subscribers</h2>
            <p-card
              class="mx-auto block md:mx-0 md:my-auto"
              styleClass="padding-clear carpool-list shadow-none rounded-tl-none rounded-bl-none"
            >
              <div class="flex px-3 py-2 text-xl font-bold">
                <p-button styleClass="" (click)="onClickNewsLetter()">
                  <span class="material-icons w-[24px]">arrow_backward</span>
                </p-button>
                <span class="ml-4">Newsletter</span>
              </div>
              <ul role="list">
                <li
                  class="h-level-3 flex cursor-pointer justify-between px-2 py-3"
                  *ngFor="let subscriber of newsletterSubscribers"
                >
                  <span>{{ subscriber.email }}</span>
                </li>
              </ul>
            </p-card>
          </div>
        </div>
      </div>
    </section>
  </div>
</div>

<div class="overlay" *ngIf="isLoading">
  <p-progressSpinner styleClass="horizontal-center"></p-progressSpinner>
</div>

<p-dialog
  [(visible)]="createNewsletterVisible"
  [modal]="true"
  [style]="{ width: '33vw' }"
  [breakpoints]="{ '960px': '95vw' }"
  [appendTo]="'body'"
>
  <ng-template pTemplate="header">
    <span class="h-level-2 font-bold">Create Newsletter</span>
  </ng-template>
  <form>
    <div class="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
      <div class="col-span-6 md:col-span-6">
        <label for="newsletter_name" class="form-title block leading-6"
          >Name
        </label>
        <div class="mt-2">
          <input
            pInputText
            type="text"
            id="newsletter_name"
            placeholder="Weelkly Roundup"
            class="block h-12 w-full rounded-md border px-2 py-1.5"
          />
        </div>
      </div>

      <div class="col-span-6 md:col-span-6">
        <label for="newsletter_desc" class="form-title block leading-6"
          >Description</label
        >
        <div class="mt-2">
          <textarea
            pInputTextarea
            rows="5"
            cols="30"
            id="newsletter_desc"
            class="block w-full rounded-md border px-2 py-1.5"
          ></textarea>
        </div>
      </div>
      <div class="col-span-6 md:col-span-6">
        <div class="flex gap-3">
          <div class="inline-flex flex-col">
            <span class="font-semibold">Opt-in existing subscribers</span>
            <span class="h-level-4"
              >This newsletter will be available to all members. Your 0 existing
              subscribers will also be opted-in to receive it.</span
            >
          </div>
          <p-inputSwitch></p-inputSwitch>
        </div>
      </div>
    </div>
  </form>
  <ng-template pTemplate="footer">
    <div class="flex justify-end">
      <button
        type="button"
        pButton
        (click)="createNewsletterVisible = false"
        class="mp-button mp-button-outlined mr-3 flex truncate rounded-md px-5 py-2 transition-all lg:mb-0 lg:w-auto"
      >
        <span>Cancel</span>
      </button>
      <button
        type="button"
        pButton
        class="mp-button mp-button-filled flex truncate rounded-md px-5 py-2 transition-all lg:mb-0 lg:w-auto"
      >
        <span>Create</span>
      </button>
    </div>
  </ng-template>
</p-dialog>
