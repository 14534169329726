<div class="surface-ground relative h-full overflow-auto">
  <div class="linear max mx-auto mb-24 transition-all duration-500">
    <form [formGroup]="previewFormGroup" (ngSubmit)="onSubmit()">
      <section class="surface-overlay sticky top-0 z-[200]">
        <div class="flex items-center justify-between p-4">
          <div class="flex cursor-pointer items-center gap-2">
            <div>
              <button
                type="button"
                pButton
                (click)="backToEdit()"
                class="mp-button mp-button-outlined inline-flex truncate rounded-md px-5 py-2 transition-all lg:mb-0 lg:w-auto"
              >
                <span>Back</span>
              </button>
            </div>
          </div>
          <div class="flex items-center gap-3">
            <div>
              <button
                type="button"
                pButton
                (click)="schedule()"
                class="mp-button mp-button-outlined flex truncate rounded-md px-5 py-2 transition-all lg:mb-0 lg:w-auto"
              >
                <span class="material-icons">schedule</span>
              </button>
            </div>
            <div class="">
              <button
                type="submit"
                pButton
                class="mp-button mp-button-filled flex truncate rounded-md px-5 py-2 transition-all lg:mb-0 lg:w-auto"
              >
                <span>Publish</span>
              </button>
            </div>
          </div>
        </div>
      </section>
      <section class="surface-overlay m-2 rounded-md">
        <div
          class="mx-auto flex max-w-[780px] flex-col items-start justify-start py-3"
        >
          <p-card
            *ngIf="!isLoading && draftedInspiration"
            class="padding-clear mx-auto block w-full md:mx-0 md:my-auto"
            styleClass="px-2 pt-2 pb-2 shadow-none"
          >
            <div class="mb-8 block px-2 md:hidden">
              <h1 class="h-level-1 mb-5">
                {{ draftedInspiration.title }}
              </h1>
              <p class="h-level-4 mb-5">
                Collaboration can make our teams stronger, and our individual
                designs better. Here’s how to do it well.
              </p>
              <div class="flex cursor-pointer items-center gap-2">
                <p-avatar styleClass="mr-2 block" size="large" shape="circle">
                  <img [src]="'../assets/images/user-pic-default.svg'" alt="" />
                </p-avatar>
                <div class="flex flex-col">
                  <div class="font-semibold">
                    <span>{{ draftedInspiration.author.displayName }}</span>
                  </div>
                  <span class="h-level-4">Published 14 Jan 2025</span>
                </div>
              </div>
            </div>
            <div class="relative mb-4 aspect-video rounded-md">
              <div
                class="inspiration-gradient absolute bottom-0 left-0 right-0 top-0"
              >
                <img
                  *ngIf="draftedInspiration.coverImageId"
                  [src]="getInspirationCoverImage() | image | async"
                  alt="cropped image"
                  class="h-full w-full rounded-md object-contain object-center"
                />
                <img
                  *ngIf="!draftedInspiration.coverImageId"
                  [src]="'../assets/images/inspiration-default-cover.svg'"
                  alt="cropped image"
                  class="h-full w-full rounded-md object-contain object-center"
                />
              </div>
              <div
                class="absolute bottom-0 left-0 right-0 hidden w-full px-4 py-6 md:block"
              >
                <h1 class="h-level-1 !font-medium text-white">
                  {{ draftedInspiration.title }}
                </h1>
                <p class="h-level-5 gray-50 mb-5">
                  {{ draftedInspiration.snippet }}
                </p>
                <div
                  class="flex cursor-pointer items-center gap-2 gap-4 text-white"
                >
                  <div class="inline-flex flex-col">
                    <h4 class="h-level-5 mb-1 font-semibold">Written by</h4>
                    <div class="inline-flex items-center">
                      <p-avatar styleClass="mr-2 block h-4 w-4" shape="circle">
                        <!-- <img
                        *ngIf="draftedInspiration.author.profilePicUrl"
                        [src]="
                        getProfileImage(draftedInspiration.author.id) | image | async
                        "
                        alt=""
                      />
                      <img
                        *ngIf="!draftedInspiration.author.profilePicUrl"
                        [src]="'../assets/images/user-pic-default.svg'"
                        alt=""
                      /> -->
                        <img
                          [src]="'../assets/images/user-pic-default.svg'"
                          alt=""
                        />
                      </p-avatar>
                      <!-- <span class="h-level-5">{{
                      draftedInspiration.publisher.ofType === "PAGE"
                        ? draftedInspiration.publisher.ofObject.name
                        : draftedInspiration.publisher.ofObject.displayName
                    }}</span> -->
                      <span class="h-level-5">{{
                        draftedInspiration.author.displayName
                      }}</span>
                    </div>
                  </div>
                  <p-divider
                    layout="vertical"
                    styleClass="mp-divider m-0 p-2"
                  ></p-divider>
                  <div class="inline-flex flex-1 flex-col">
                    <h4 class="h-level-5 font-semibold">Published On</h4>
                    <span class="h-level-5">
                      <span
                        class="material-icons inline-block align-sub !text-base"
                        >calendar_month</span
                      >
                      <span class="ml-1">{{
                        draftedInspiration.draftDate | date: "mediumDate"
                      }}</span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <app-inspiration-content
              [contentHTML]="content"
            ></app-inspiration-content>
          </p-card>
          <div class="relative my-8 w-full border-b text-center text-base">
            <span
              class="surface-overlay absolute -top-[8px] left-[50%] block -translate-x-2/4 px-2 leading-4"
              >Additional Settings</span
            >
          </div>
          <p-card
            class="padding-clear mx-auto block w-full md:mx-0 md:my-auto"
            styleClass="px-2 py-2"
          >
            <div class="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div class="col-span-6">
                <div class="my-0">
                  <label for="venueNote" class="form-title block leading-6"
                    >SEO Description
                    <span
                      class="material-icons sub-title"
                      pTooltip="SEO Description is used in place of subtitle on search engine results pages, such as a Google search."
                      >info</span
                    ></label
                  >
                  <div class="mt-2">
                    <textarea
                      pInputTextarea
                      rows="5"
                      cols="30"
                      id="venueNote"
                      formControlName="snippet"
                      class="block w-full rounded-md border px-2 py-1.5"
                    ></textarea>
                  </div>
                </div>
              </div>
              <div class="col-span-6 md:col-span-6">
                <label class="form-title mb-1 block leading-6"
                  >Publish In
                  <span
                    class="material-icons sub-title"
                    pTooltip="These are categories your inpiration will be categoried in."
                    >info</span
                  ></label
                >
                <div class="mt-2 flex flex-col gap-3 md:flex-row">
                  <div class="relative flex-1">
                    <div
                      class="activity flex flex-1 rounded-md border shadow-sm"
                      [ngClass]="{
                        'border-red-300':
                          submitted && f['categoryPrimary'].errors,
                      }"
                    >
                      <p-dropdown
                        class="w-full"
                        inputId="primary-category"
                        [options]="categoriesA"
                        optionLabel="label"
                        formControlName="categoryPrimary"
                        placeholder="Select Primary Category"
                      ></p-dropdown>
                      <span
                        class="-top[10px] absolute -right-[10px] text-red-500"
                        >*</span
                      >
                    </div>
                    <div
                      *ngIf="submitted && f['categoryPrimary'].errors"
                      class="invalid-feedback text-red-500"
                    >
                      <small *ngIf="f['categoryPrimary'].errors['required']"
                        >Primary Category is required</small
                      >
                    </div>
                  </div>
                  <div class="flex-1">
                    <div
                      class="activity flex flex-1 rounded-md border shadow-sm"
                    >
                      <p-dropdown
                        class="w-full"
                        [options]="categoriesB"
                        optionLabel="label"
                        [showClear]="true"
                        formControlName="categorySecondary"
                        placeholder="Select Secondary Category"
                      ></p-dropdown>
                    </div>
                    <div
                      *ngIf="submitted && f['categoryPrimary'].errors"
                      class="invalid-feedback invisible text-red-500"
                    ></div>
                  </div>
                </div>
              </div>
              <div class="col-span-6 md:col-span-6">
                <label class="form-title mb-1 block leading-6"
                  >Related Activities
                  <span
                    class="material-icons sub-title"
                    pTooltip="Is your inspiration related to any outdoor activity?"
                    >info</span
                  ></label
                >
                <div class="mt-2 flex flex-col gap-3 md:flex-row">
                  <div class="relative flex-1">
                    <div
                      class="activity flex flex-1 rounded-md border shadow-sm"
                      [ngClass]="{
                        'border-red-300':
                          submitted && f['activityPrimary'].errors,
                      }"
                    >
                      <p-dropdown
                        class="w-full"
                        inputId="primary-category"
                        [options]="activitiesA"
                        optionLabel="label"
                        formControlName="activityPrimary"
                        placeholder="Select Primary Activity"
                      ></p-dropdown>
                      <span
                        class="-top[10px] absolute -right-[10px] text-red-500"
                        >*</span
                      >
                    </div>
                    <div
                      *ngIf="submitted && f['activityPrimary'].errors"
                      class="invalid-feedback text-red-500"
                    >
                      <small *ngIf="f['activityPrimary'].errors['required']"
                        >Primary Activity is required</small
                      >
                    </div>
                  </div>
                  <div class="flex-1">
                    <div
                      class="activity flex flex-1 rounded-md border shadow-sm"
                    >
                      <p-dropdown
                        class="w-full"
                        [options]="activitiesB"
                        optionLabel="label"
                        [showClear]="true"
                        formControlName="activitySecondary"
                        placeholder="Select Secondary Activity"
                      ></p-dropdown>
                    </div>
                    <div
                      *ngIf="submitted && f['activityPrimary'].errors"
                      class="invalid-feedback invisible text-red-500"
                    ></div>
                  </div>
                </div>
              </div>
              <div class="col-span-6">
                <div class="px-2">
                  <label for="venueNote" class="form-title block leading-6"
                    >Comments</label
                  >
                  <span class="block"
                    >Choose if you want to allow comments and enable
                    moderation</span
                  >
                  <div class="mt-2">
                    <div class="field-checkbox mb-2">
                      <p-checkbox
                        inputId="allow-comments"
                        [binary]="true"
                        formControlName="commentAllowed"
                      ></p-checkbox>
                      <label for="allow-comments" class="ml-2"
                        >Allow comments on your inspiration</label
                      >
                    </div>
                    <div class="field-checkbox">
                      <p-checkbox
                        inputId="enable-moderation"
                        [binary]="true"
                        formControlName="commentModeration"
                      ></p-checkbox>
                      <label for="enable-moderation" class="ml-2"
                        >Enable moderation of comments before it can publically
                        disaply on your inspiration
                        <span
                          class="material-icons sub-title"
                          pTooltip="Enable Moderation"
                          >info</span
                        ></label
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-span-6">
                <label class="form-title block leading-6">Visibility</label>
                <div class="mt-2 flex gap-4">
                  <div
                    *ngFor="let visibility of visibilities"
                    class="field-checkbox"
                  >
                    <p-radioButton
                      [inputId]="visibility.key"
                      [value]="visibility.key"
                      formControlName="visibility"
                    ></p-radioButton>
                    <label [for]="visibility.key" class="ml-2">{{
                      visibility.name
                    }}</label>
                  </div>
                </div>
              </div>
              <div class="col-span-6">
                <div class="col-span-6 md:col-span-6">
                  <label for="user_defined" class="form-title block leading-6"
                    >Topics
                  </label>
                  <span class="block"
                    >Add topics (upto 5) so reader know what inspiration is
                    about</span
                  >
                  <div class="mb-3 mt-2">
                    <p-chips
                      inputId="user_defined"
                      [styleClass]="
                        'w-full rounded-md border ' +
                        (submitted && f['tags'].errors ? 'border-red-300' : '')
                      "
                      [separator]="separatorExp"
                      [max]="5"
                      formControlName="tags"
                    ></p-chips>
                    <div
                      *ngIf="submitted && f['tags'].errors"
                      class="invalid-feedback text-red-500"
                    >
                      <small *ngIf="f['tags'].errors['notAlphanumeric']"
                        >Tag name should be alphanumeric, start with a letter
                        and have a length of 3-20 characters</small
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </p-card>
        </div>
      </section>
    </form>
  </div>
</div>
<div class="overlay" *ngIf="isLoading">
  <p-progressSpinner styleClass="horizontal-center"></p-progressSpinner>
</div>

<p-dialog
  [(visible)]="scheduleVisible"
  [modal]="true"
  [style]="{ width: '33vw' }"
  [breakpoints]="{ '960px': '95vw' }"
  [appendTo]="'body'"
  (onShow)="scheduleDialog()"
  (onHide)="scheduleDialog(!0)"
>
  <ng-template pTemplate="header">
    <span class="h-level-2 font-bold">Schedule Publish</span>
  </ng-template>
  <app-datepicker
    [showTime]="true"
    [event]="dateTimePickerEvent.asObservable()"
    datepickerLabel="Date"
    timerLabel="Time"
    (selectTime)="onTimeSelect($event)"
    (selectDate)="onDateSelect($event)"
    [isInvalid]="submitted && isPastDate"
    [showRequired]="!0"
    [showRequiredMessage]="submitted && scheduleVisible"
    [showValidationMessage]="submitted && isPastDate"
    [validationMessage]="isPastDate ? 'Date and Time cannot be past' : ''"
  ></app-datepicker>
  <ng-template pTemplate="footer">
    <div class="float-right">
      <button
        type="button"
        pButton
        (click)="onSubmit()"
        [disabled]="
          (!previewFormGroup.get('scheduleDate')?.value && !timePicker) ||
          isLoading
        "
        class="mp-button mp-button-filled flex truncate rounded-md px-5 py-2 transition-all lg:mb-0 lg:w-auto"
      >
        <span>{{ isLoading ? "Scheduling..." : "Schedule Publish" }}</span>
      </button>
    </div>
  </ng-template>
</p-dialog>
