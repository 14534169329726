<div
  class="surface-ground relative h-full overflow-auto p-2"
  *ngIf="!isLoading"
>
  <div class="linear max mx-auto mb-24 transition-all duration-500">
    <section class="mx-auto max-w-[780px] rounded-md">
      <div class="flex flex-col items-start justify-start py-3">
        <div
          class="search-results w-full"
          infinite-scroll
          [infiniteScrollDistance]="1"
          [infiniteScrollUpDistance]="2"
          [infiniteScrollThrottle]="500"
          [infiniteScrollContainer]="'.mat-sidenav-content'"
          [fromRoot]="true"
          *ngIf="!isLoading"
        >
          <div class="mb-8 last:mb-0">
            <h2 class="h-level-2 mb-2 border-b">Personal publisher profile</h2>
            <div>
              <div
                class="shipping-item relative shrink grow basis-0 p-[4px]"
                *ngFor="let profile of publishersPersonalProfile"
              >
                <p-card
                  class="mx-auto block md:mx-0 md:my-auto"
                  styleClass="padding-clear authored-list"
                >
                  <div
                    class="grid grid-cols-1 gap-x-6 gap-y-8 p-5 sm:grid-cols-6"
                  >
                    <div class="col-span-6 md:col-span-6">
                      <div
                        class="grow-1 stretch-1 flex-start flex items-center"
                      >
                        <div class="mr-4 flex">
                          <span class="inline">
                            <div class="relative h-[40px] w-[40px]">
                              <p-avatar
                                styleClass="mr-2 h-[40px] w-[40px]"
                                shape="circle"
                                size="large"
                              >
                                <img
                                  *ngIf="profile.ofObject.profilePicUrl"
                                  [src]="
                                    getProfileImage(profile.ofObject.id)
                                      | image
                                      | async
                                  "
                                  alt=""
                                />
                                <img
                                  *ngIf="!profile.ofObject.profilePicUrl"
                                  [src]="
                                    '../assets/images/user-pic-default.svg'
                                  "
                                  alt=""
                                />
                              </p-avatar>
                            </div>
                          </span>
                        </div>
                        <div class="grow" (click)="redirect(profile.id)">
                          <div class="flex flex-col">
                            <div class="h-level-3 cursor-pointer">
                              <span>{{ profile.ofObject.displayName }}</span>
                            </div>
                            <div class="">
                              <span
                                class="block min-w-0 max-w-full break-words text-left text-[0.8rem]"
                              >
                                &commat;{{ profile.ofObject.username }}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div class="">
                          <button
                            [disabled]="userInfo.id === profile.ofObject.id"
                            pButton
                            class="mp-button mp-button-filled h-[32px] rounded-md px-2 py-2"
                          >
                            Follow
                          </button>
                        </div>
                      </div>
                      <div
                        *ngIf="profile.intro"
                        class="mt-2 cursor-pointer"
                        (click)="redirect(profile.id)"
                      >
                        {{ profile.intro }}
                      </div>
                    </div>
                  </div>
                </p-card>
              </div>
            </div>
          </div>

          <div class="mb-8 last:mb-0" *ngIf="publishersPageProfile.length">
            <h2 class="h-level-2 mb-2 border-b">
              Publisher profiles of pages you manage
            </h2>
            <div>
              <div
                class="shipping-item relative shrink grow basis-0 p-[4px]"
                *ngFor="let profile of publishersPageProfile"
              >
                <p-card
                  class="mx-auto block md:mx-0 md:my-auto"
                  styleClass="padding-clear authored-list"
                >
                  <div
                    class="grid grid-cols-1 gap-x-6 gap-y-8 p-5 sm:grid-cols-6"
                  >
                    <div class="col-span-6 md:col-span-6">
                      <div
                        class="grow-1 stretch-1 flex-start flex items-center"
                      >
                        <div class="mr-4 flex">
                          <span class="inline">
                            <div class="relative h-[40px] w-[40px]">
                              <p-avatar
                                styleClass="mr-2 h-[40px] w-[40px]"
                                shape="circle"
                                size="large"
                              >
                                <span class="material-icons !text-lg"
                                  >article</span
                                >
                              </p-avatar>
                            </div>
                          </span>
                        </div>
                        <div class="grow" (click)="redirect(profile.id)">
                          <div class="flex flex-col">
                            <div class="h-level-3 cursor-pointer">
                              <span>{{ profile.ofObject.name }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="">
                          <button
                            pButton
                            [disabled]="userInfo.id === profile.ofObject.ofId"
                            class="mp-button mp-button-filled h-[32px] rounded-md px-2 py-2"
                          >
                            Follow
                          </button>
                        </div>
                      </div>
                      <div
                        *ngIf="profile.intro"
                        class="mt-2 cursor-pointer"
                        (click)="redirect(profile.id)"
                      >
                        {{ profile.intro }}
                      </div>
                    </div>
                  </div>
                </p-card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</div>
<div class="overlay" *ngIf="isLoading">
  <p-progressSpinner styleClass="horizontal-center"></p-progressSpinner>
</div>
