import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { InspirationService } from 'src/app/main/services/inspiration.service';
import {
  NewsletterCategory,
  NewsletterSubscribers,
} from 'src/app/main/types/main.types';

@Component({
  selector: 'app-newsletter-and-subscribers',
  templateUrl: './newsletter-and-subscribers.component.html',
})
export class NewsletterAndSubscribersComponent implements OnInit {
  isLoading = false;

  checked = false;

  showSubscriber = false;

  createNewsletterVisible = false;

  categories: NewsletterCategory[];

  newsletterSubscribers: NewsletterSubscribers[];

  constructor(
    private messageService: MessageService,
    private router: Router,
    private inspirationService: InspirationService,
  ) {}

  ngOnInit() {
    this.getCategories();
    this.getNewsletterSubscribers();
  }

  getCategories() {
    this.inspirationService.getNewsLetterCategories().subscribe({
      next: (data: any) => {
        this.categories = data;
      },
      error: (err) => {
        const { errors = null } = err?.error || {};
        this.messageService.add({
          severity: 'error',
          summary: 'Newsletter Categories',
          detail: errors ? errors[0] : 'Something went wrong',
        });
      },
    });
  }

  getNewsletterSubscribers() {
    this.inspirationService.getNewsLetterSubscribers().subscribe({
      next: (data: any) => {
        this.newsletterSubscribers = data;
      },
      error: (err) => {
        const { errors = null } = err?.error || {};
        this.messageService.add({
          severity: 'error',
          summary: 'Init Newsletter',
          detail: errors ? errors[0] : 'Something went wrong',
        });
      },
    });
  }

  onClickNewsLetter() {
    if (window.innerWidth <= 430) this.showSubscriber = !this.showSubscriber;
  }
}
