<div class="surface-ground container">
  <div class="mx-auto max-w-[780px] p-2">
    <div class="linear max mx-auto mb-24 transition-all duration-500">
      <form [formGroup]="publisherProfileFormGroup" (ngSubmit)="onSubmit()">
        <div class="container mb-8 mt-4">
          <h2 class="h-level-2 mb-4" *ngIf="publisherProfile?.ofType">
            {{
              publisherProfile.ofType === "USER"
                ? "Editing personal publisher profile"
                : "Editing Publishing Profile of " +
                  (publisherProfile.ofObject?.name || "Page Name")
            }}
          </h2>
          <p-card
            class="mx-auto block md:mx-0 md:my-auto"
            styleClass="padding-clear"
          >
            <div class="grid grid-cols-1 gap-x-6 gap-y-8 p-3 sm:grid-cols-6">
              <div class="col-span-6 md:col-span-6">
                <label for="title" class="form-title block leading-6"
                  >Title</label
                >
                <div class="mt-2">
                  <input
                    pInputText
                    formControlName="title"
                    type="text"
                    id="title"
                    class="block h-12 w-full rounded-md border px-2 py-1.5"
                  />
                </div>
              </div>

              <div class="col-span-6 md:col-span-6">
                <label for="intro" class="form-title block leading-6"
                  >Brief Intro</label
                >
                <div class="mt-2">
                  <textarea
                    pInputTextarea
                    rows="3"
                    cols="30"
                    id="intro"
                    formControlName="intro"
                    class="block w-full rounded-md border px-2 py-1.5"
                  ></textarea>
                </div>
              </div>

              <div class="col-span-6 md:col-span-6">
                <label for="description" class="form-title block leading-6"
                  >Description</label
                >
                <div class="mt-2">
                  <p-editor
                    #editor
                    class="flex-1"
                    (onInit)="onEditorInit($event)"
                    (onTextChange)="textChange($event)"
                    formControlName="description"
                    [style]="{ height: '150px' }"
                  >
                    <ng-template pTemplate="header">
                      <span class="ql-formats">
                        <button
                          type="button"
                          class="ql-bold"
                          aria-label="Bold"
                        ></button>
                        <button
                          type="button"
                          class="ql-italic"
                          aria-label="Italic"
                        ></button>
                        <button
                          type="button"
                          class="ql-underline"
                          aria-label="Underline"
                        ></button>
                      </span>
                      <span class="ql-formats">
                        <button
                          type="button"
                          class="ql-link"
                          aria-label="Link"
                        ></button>
                      </span>
                    </ng-template>
                  </p-editor>
                </div>
              </div>

              <div class="col-span-6 md:col-span-6">
                <p-divider styleClass="mp-divider my-2"></p-divider>
              </div>

              <div class="col-span-6 md:col-span-6">
                <label for="description" class="form-title block leading-6"
                  >Preview</label
                >
                <p class="sub-title mt-1 leading-6">
                  This is how your intro will look like in inspirations.
                </p>

                <div class="surface-g-border col-span-6 rounded-[15px] border">
                  <div
                    class="flex flex-col items-start gap-2 rounded-md p-4"
                    *ngIf="publisherProfile?.ofObject"
                  >
                    <div class="grow-1 flex w-full items-center">
                      <p-avatar
                        styleClass="mr-2 block"
                        size="large"
                        shape="circle"
                        *ngIf="publisherProfile.ofType === 'USER'"
                      >
                        <img
                          *ngIf="publisherProfile.ofObject.profilePicUrl"
                          [src]="
                            getProfileImage(publisherProfile.ofObject.id)
                              | image
                              | async
                          "
                          alt=""
                        />
                        <img
                          *ngIf="!publisherProfile.ofObject.id"
                          [src]="'../assets/images/user-pic-default.svg'"
                          alt=""
                        />
                      </p-avatar>
                      <p-avatar
                        styleClass="mr-2 block flex"
                        size="large"
                        shape="circle"
                        *ngIf="publisherProfile.ofType === 'PAGE'"
                      >
                        <span class="material-icons !text-xl">article</span>
                      </p-avatar>
                      <div class="flex flex-1 flex-col">
                        <div class="font-semibold">
                          <span class="highlight-text font-medium">{{
                            publisherProfile.ofType === "PAGE"
                              ? publisherProfile.ofObject.name
                              : publisherProfile.ofObject.displayName
                          }}</span>
                          <div
                            class="truncate"
                            *ngIf="publisherProfile.ofType === 'USER'"
                          >
                            <a
                              [routerLink]="[
                                '/',
                                publisherProfile.ofObject.username,
                              ]"
                              class="primary transition duration-300"
                              >&commat;{{
                                publisherProfile.ofObject.username
                              }}</a
                            >
                          </div>
                        </div>
                      </div>
                      <div class="flex items-center justify-center">
                        <button
                          type="button"
                          pButton
                          [disabled]="true"
                          class="mp-button mp-button-filled mb-0 flex truncate rounded-md px-5 py-2 transition-all lg:w-auto"
                        >
                          Follow
                        </button>
                      </div>
                    </div>
                    <p>
                      {{ publisherProfileFormGroup.get("intro")?.value }}
                    </p>
                  </div>
                  <div
                    [ngClass]="{
                      'surface-light-200': isDark,
                      'surface-light-100': !isDark,
                    }"
                    class="surface-g-border m-3 rounded-lg border"
                  >
                    <p
                      class="publisher-profile-desc p-4"
                      #descriptionPreview
                    ></p>
                  </div>
                </div>
              </div>
            </div>
          </p-card>
        </div>

        <div class="my-6 flex justify-between gap-4">
          <div class="flex">
            <button
              pButton
              (click)="redirect(['/inspiration', 'publishers'])"
              class="mp-button mp-button-outlined rounded-md px-5 py-2 leading-6 shadow-sm"
            >
              Cancel
            </button>
          </div>
          <div class="flex gap-2">
            <button
              pButton
              type="button"
              (click)="reset()"
              class="mp-button mp-button-filled rounded-md px-5 py-2 leading-6 shadow-sm"
            >
              Reset
            </button>
            <button
              pButton
              type="submit"
              pButton
              [loading]="isLoading"
              class="mp-button mp-button-filled rounded-md px-5 py-2 leading-6 shadow-sm"
            >
              Save
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

<div class="overlay" *ngIf="isLoading">
  <p-progressSpinner styleClass="horizontal-center"></p-progressSpinner>
</div>
