<div
  #inspirationBookmarked
  class="surface-ground relative h-full overflow-auto p-2"
>
  <div class="linear max mx-auto mb-24 transition-all duration-500">
    <section class="mx-auto max-w-[780px] rounded-md">
      <div class="flex flex-col items-start justify-start py-3">
        <div
          class="search-results w-full"
          infinite-scroll
          [infiniteScrollDistance]="1"
          [infiniteScrollUpDistance]="2"
          [infiniteScrollThrottle]="500"
          (scrolled)="onScrolledDown()"
          [infiniteScrollContainer]="inspirationBookmarked"
          [fromRoot]="true"
          *ngIf="!isLoading"
        >
          <div class="mb-8 last:mb-0" *ngFor="let date of _groupByDate">
            <h2 class="h-level-2 mb-2 border-b">
              {{ _getKey(date) }}
            </h2>
            <div *ngIf="_getValue(date)">
              <div
                class="shipping-item relative shrink grow basis-0 p-[4px]"
                *ngFor="let bookmark of $any(_getValue(date))"
              >
                <div *ngIf="bookmark.featured" class="ribbon ribbon-top-left">
                  <span>Featured</span>
                </div>
                <p-card
                  class="mx-auto block md:mx-0 md:my-auto"
                  styleClass="padding-clear authored-list"
                >
                  <div
                    class="grid grid-cols-1 gap-x-2 gap-y-3 p-2 sm:grid-cols-6"
                  >
                    <div
                      class="col-span-6 cursor-pointer md:col-span-3"
                      (click)="onBookmarkedView('' + bookmark.id)"
                    >
                      <img
                        *ngIf="bookmark.coverImageId"
                        [src]="
                          getInspirationCoverImage(bookmark.id) | image | async
                        "
                        alt="cropped image"
                        class="h-full w-full rounded-md object-contain object-top"
                      />
                      <img
                        *ngIf="!bookmark.coverImageId"
                        src="../assets/images/inspiration-default-cover.svg"
                        alt="cropped image"
                        class="h-full w-full rounded-md object-contain object-top"
                      />
                    </div>
                    <div class="col-span-6 md:col-span-3">
                      <div class="flex h-full flex-col">
                        <div class="mb-3 flex items-center justify-between">
                          <div class="inline-flex"></div>
                          <button
                            pButton
                            (click)="toggleMenu($event, bookmark)"
                            class="prevent mx-3 h-[32px] rounded-full p-1"
                          >
                            <span class="material-icons prevent">
                              more_horiz
                            </span>
                          </button>
                        </div>
                        <span class="h-level-3 mb-3 block">{{
                          bookmark.title
                        }}</span>
                        <p class="mb-2 flex-1" *ngIf="bookmark.snippet">
                          {{ bookmark.snippet.slice(0, 180) }}
                          <span *ngIf="!showSnippet[bookmark.id]">...</span>
                          <span *ngIf="showSnippet[bookmark.id]">
                            {{
                              bookmark.snippet.slice(
                                180,
                                bookmark.snippet.length
                              )
                            }}
                          </span>
                          <span
                            class="primary cursor-pointer"
                            (click)="showTruncateText(bookmark.id)"
                            >{{
                              !showSnippet[bookmark.id] ? "more" : "less"
                            }}</span
                          >
                        </p>
                        <div class="flex justify-between">
                          <span class="h-level-5 primary font-semibold">
                            {{ "Bookmarked on" }}
                            {{ bookmark.bookmarkDate | date: "medium" }}
                          </span>
                          <span class="h-level-5 primary font-semibold">
                            {{ visibilityMap[bookmark.visibility] }}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </p-card>
              </div>
            </div>
            <div
              *ngIf="!$any(_getValue(date))?.length"
              class="mt-2 flex items-center gap-3"
            >
              <span class="material-icons">calendar_today</span>
              <span>No matches found.</span>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</div>
<p-tieredMenu
  #bookmarkMenu
  [model]="bookmarkOptions"
  appendTo="body"
  [popup]="true"
  styleClass="p-0"
>
  <ng-template pTemplate="p-menuitem" let-obj>
    <div
      (click)="onTieredMenuClick(obj.id)"
      class="flex cursor-pointer px-2 py-3"
      *ngIf="obj.item === 'bookmark'"
      [ngClass]="{ 'pointer-events-none opacity-80': obj?.disabled }"
    >
      <div class="font-normal">
        <a>{{ obj.label }}</a>
      </div>
    </div>
  </ng-template>
</p-tieredMenu>

<app-sharelink
  *ngIf="inspirationId"
  [event]="shareLinkEvent.asObservable()"
  objectType="INSPIRATION"
  [objectId]="inspirationId"
></app-sharelink>

<div class="overlay" *ngIf="isLoading">
  <p-progressSpinner styleClass="horizontal-center"></p-progressSpinner>
</div>
