import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { InspirationService } from 'src/app/main/services/inspiration.service';
import moment from 'moment';
import {
  publishedInMap,
  relatedActivitiesMap,
  visibilityMap,
} from 'src/app/main/utilities';
import {
  InspirationDraft,
  PublishInspiration,
  ScheduledPublishInspiration,
} from 'src/app/main/types/main.types';
import { inspirationOptions } from 'src/app/main/utilities/overlayHelper';
import { TieredMenu } from 'primeng/tieredmenu';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-inspiration-authored',
  templateUrl: './inspiration-authored.component.html',
})
export class InspirationAuthoredComponent implements OnInit {
  isLoading = false;

  currentPage = 1;

  tabId = 'published';

  publishedId = 0;

  queryTab = '';

  inspirationId = 0;

  publishedInspirations: PublishInspiration[] = [];

  scheduleAndDraft: InspirationDraft[] = [];

  groupDate: any = [];

  publishedInMap = publishedInMap;

  inspirationOptions = inspirationOptions;

  relatedActivitiesMap = relatedActivitiesMap;

  visibilityMap = visibilityMap;

  selectButtonOptions: any = [];

  selectedButton = 'published';

  date: string;

  time: { time: Date; hour: number; min: number };

  scheduleVisible = false;

  showSnippet: any = {};

  feature = false;

  datetimeValid = false;

  dateTimePickerEvent: Subject<any> = new Subject<any>();

  shareLinkEvent: Subject<boolean> = new Subject<boolean>();

  @ViewChild('inspirationMenu', { static: false })
  inspirationMenuRef: TieredMenu;

  constructor(
    private messageService: MessageService,
    private router: Router,
    private route: ActivatedRoute,
    private inspirationService: InspirationService,
  ) {}

  ngOnInit(): void {
    const params: any = this.route.snapshot.queryParams;
    this.selectButtonOptions = [
      { label: 'Published', value: 'published', icon: 'task' },
      { label: 'Scheduled', value: 'scheduled', icon: 'schedule' },
      { label: 'Draft', value: 'draft', icon: 'edit_document' },
    ];

    if (params?.tab && params?.id) {
      this.tabId = params.tab;
      this.queryTab = this.tabId;
      this.selectedButton = this.tabId;
      this.publishedId = Number(params.id);
      this.onSelectAction({ value: this.tabId });
    } else {
      this.getPublishedInspirations(this.currentPage - 1);
    }
    setTimeout(() => (this.publishedId = 0), 4000);
  }

  redirect() {
    this.inspirationService.createInspiration().subscribe({
      next: (data: any) => {
        this.router.navigate(['/inspiration', 'edit'], {
          queryParams: { draftId: data.id },
        });
      },
      error: (err) => {
        const { errors = null } = err?.error || {};
        this.messageService.add({
          severity: 'error',
          summary: 'Create Inspiration',
          detail: errors ? errors[0] : 'Something went wrong',
        });
      },
    });
  }

  onScrolledDown() {
    this.currentPage++;
    if (this.tabId === 'draft') {
      this.getScheduleAndDraftInspirations(this.currentPage - 1, 'DRAFT');
    } else if (this.tabId === 'scheduled') {
      this.getScheduleAndDraftInspirations(this.currentPage - 1, 'SCHEDULE');
    } else {
      this.getPublishedInspirations(this.currentPage - 1);
    }
  }

  getKey(k: any) {
    return Object.keys(k)[0];
  }

  getValue(k: any) {
    return Object.values(k)[0];
  }

  onSelectAction(e: any) {
    const { value } = e;
    this.currentPage = 1;
    this.publishedInspirations = [];
    this.scheduleAndDraft = [];
    this.groupDate = [];
    this.showSnippet = {};
    this.isLoading = true;
    if (value === 'scheduled') {
      this.getScheduleAndDraftInspirations(this.currentPage - 1, 'SCHEDULE');
    } else if (value === 'draft') {
      this.getScheduleAndDraftInspirations(this.currentPage - 1, 'DRAFT');
    } else if (value === 'published') {
      this.getPublishedInspirations(this.currentPage - 1);
    }
    this.tabId = value;
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { tab: this.tabId },
    });
  }

  getPublishedInspirations(page: number) {
    this.inspirationService.getPublishedInspirations(page).subscribe({
      next: (data: any) => {
        this.publishedInspirations = [
          ...this.publishedInspirations,
          ...data.content,
        ];
        this.showSnippet = this.publishedInspirations.reduce(
          (prev: any, initial: any) => {
            prev[initial.id] = false;
            return prev;
          },
          {},
        );
        this.groupDate = this.groupByDate(
          this.publishedInspirations,
          'publishDate',
        );
        this.isLoading = false;
      },
      error: (err) => {
        const { errors = null } = err?.error || {};
        this.messageService.add({
          severity: 'error',
          summary: 'Published Inspirations',
          detail: errors ? errors[0] : 'Something went wrong',
        });
        this.isLoading = false;
      },
    });
  }

  getScheduleAndDraftInspirations(page: number, draftType: string) {
    this.inspirationService
      .getScheduledAndDraftInspirations(draftType, page)
      .subscribe({
        next: (data: any) => {
          this.scheduleAndDraft = [...this.scheduleAndDraft, ...data.content];
          this.showSnippet = this.scheduleAndDraft.reduce(
            (prev: any, initial: any) => {
              prev[initial.id] = false;
              return prev;
            },
            {},
          );
          this.groupDate = this.groupByDate(
            this.scheduleAndDraft,
            draftType === 'DRAFT' ? 'draftDate' : 'scheduleDate',
          );
          this.isLoading = false;
        },
        error: (err) => {
          const { errors = null } = err?.error || {};
          this.messageService.add({
            severity: 'error',
            summary: 'Published Inspirations',
            detail: errors ? errors[0] : 'Something went wrong',
          });
          this.isLoading = false;
        },
      });
  }

  toggleMenu(e: any, data: any) {
    this.inspirationMenuRef.toggle(e);
    this.inspirationId = data.id;
    this.feature = data?.featured;
  }

  groupByDate(data: any, dateObj: string) {
    const obj: any = {};
    const groupInspiration: any = [];
    const dates: any = {};
    const currentDate = moment().date();
    let hasToday = false;
    data.forEach((d: any) => {
      const date = moment(d[dateObj]);
      const inspirationDate = date.date();
      const _currentDate = moment()
        .calendar()
        .match(/(Today)/g);
      const _date =
        currentDate === inspirationDate
          ? _currentDate
            ? _currentDate[0]
            : moment().calendar()
          : date.format('dddd, MMMM Do YYYY');
      if (currentDate === inspirationDate) {
        hasToday = true;
      }
      dates[inspirationDate] = _date;
      if (obj[_date]) {
        obj[_date].push(d);
      } else {
        obj[_date] = [d];
      }
    });

    let _sortedDates: any[] = Object.values(dates);

    if (['publishDate', 'draftDate'].includes(dateObj))
      _sortedDates = Object.values(dates).sort((a: any, b: any) => {
        const dateA: any = new Date(
          a
            .replace('nd', '')
            .replace('st', '')
            .replace('rd', '')
            .replace('th', ''),
        );
        const dateB: any = new Date(
          b
            .replace('nd', '')
            .replace('st', '')
            .replace('rd', '')
            .replace('th', ''),
        );
        return dateB - dateA;
      });

    for (const sortedDate of _sortedDates) {
      groupInspiration.push({
        [sortedDate]: obj[sortedDate],
      });
    }

    if (!hasToday) {
      obj[moment().calendar()] = [];
      groupInspiration.unshift({
        [moment().calendar()]: [],
      });
    }

    return groupInspiration;
  }

  rescheduleInspiration(data: ScheduledPublishInspiration) {
    this.inspirationService.schedule(data, this.inspirationId).subscribe({
      next: (data: any) => {
        this.isLoading = false;
        this.messageService.add({
          severity: data.error ? 'error' : 'success',
          summary: 'Rescheduled Inspiration',
          detail: 'Inspiration has been rescheduled',
        });
        this.scheduleVisible = false;
        this.reset();
        this.getScheduleAndDraftInspirations(this.currentPage - 1, 'SCHEDULE');
      },
      error: (err) => {
        const { errors = null } = err?.error || {};
        this.messageService.add({
          severity: 'error',
          summary: 'Schedule Inspiration',
          detail: errors ? errors[0] : 'Something went wrong',
        });
        this.isLoading = false;
      },
    });
  }

  onDateSelect(e: any) {
    this.date = e;
  }

  onTimeSelect(e: any) {
    this.time = e.value;
  }

  onReScheduled() {
    if (this.time && this.date && this.datetimeValid) {
      this.isLoading = true;
      const scheduleDate = new Date(this.date);
      scheduleDate.setHours(this.time?.hour);
      scheduleDate.setMinutes(this.time?.min);
      this.rescheduleInspiration({ scheduleDate: scheduleDate.toISOString() });
    }
  }

  onDateTimeValidation(e: any) {
    this.datetimeValid = !e.invalid;
  }

  onTieredMenuClick(id: string) {
    switch (id) {
      case 'change_schedule':
        this.scheduleVisible = true;
        break;
      case 'draft_edit':
        this.router.navigate(['/inspiration/edit'], {
          queryParams: { draftId: this.inspirationId },
        });
        break;
      case 'view':
        this.router.navigate(['/inspiration/view', this.inspirationId]);
        break;
      case 'schedule_edit':
        this.router.navigate(['/inspiration/preview', this.inspirationId]);
        break;
      case 'share':
        this.shareLinkEvent.next(true);
        break;
      case 'feature':
        this.featuredAndUnfeatured();
        break;
      case 'discard':
        this.discardDraft();
        break;
      case 'unschedule':
        this.unSchedule();
    }
  }

  showTruncateText(id: number) {
    this.showSnippet[id] = !this.showSnippet[id];
  }

  featuredAndUnfeatured() {
    const method = this.feature ? 'unfeature' : 'feature';
    this.inspirationService[method](this.inspirationId).subscribe({
      next: (data: any) => {
        this.messageService.add({
          severity: data.error ? 'error' : 'success',
          summary: this.feature
            ? 'Featured Inspiration'
            : 'Featured Inspiration',
          detail: data.message,
        });
        this.feature = !this.feature;
        this.publishedInspirations = [];
        this.groupDate = [];
        this.getPublishedInspirations(this.currentPage - 1);
      },
      error: (err) => {
        const { errors = null } = err?.error || {};
        this.messageService.add({
          severity: 'error',
          summary: this.feature
            ? 'Featured Inspiration'
            : 'Featured Inspiration',
          detail: errors ? errors[0] : 'Something went wrong',
        });
      },
    });
  }

  discardDraft() {
    this.isLoading = true;
    this.inspirationService.discardDraft('' + this.inspirationId).subscribe({
      next: (data: any) => {
        if (data.error) {
          this.messageService.add({
            severity: 'error',
            summary: 'Discard Draft',
            detail: data.message,
          });
        } else {
          this.messageService.add({
            severity: 'success',
            summary: 'Discard Draft',
            detail: data.message,
          });
          this.reset();
          this.getScheduleAndDraftInspirations(this.currentPage - 1, 'DRAFT');
        }
      },
      error: (err) => {
        const { errors = null } = err?.error || {};
        this.messageService.add({
          severity: 'error',
          summary: 'Discard Draft',
          detail: errors ? errors[0] : 'Something went wrong',
        });
        this.isLoading = false;
      },
    });
  }

  unSchedule() {
    this.isLoading = true;
    this.inspirationService.unschedule('' + this.inspirationId).subscribe({
      next: (data: any) => {
        if (data.error) {
          this.messageService.add({
            severity: 'error',
            summary: 'Unshedule Inspiration',
            detail: data.message,
          });
        } else {
          this.messageService.add({
            severity: 'success',
            summary: 'Unshedule Inspiration',
            detail: 'Unscheduled Inspiration Successfully',
          });
          this.reset();
          this.getScheduleAndDraftInspirations(
            this.currentPage - 1,
            'SCHEDULE',
          );
        }
      },
      error: (err) => {
        const { errors = null } = err?.error || {};
        this.messageService.add({
          severity: 'error',
          summary: 'Unshedule Inspiration',
          detail: errors ? errors[0] : 'Something went wrong',
        });
        this.isLoading = false;
      },
    });
  }

  reset() {
    this.currentPage = 1;
    this.scheduleAndDraft = [];
    this.groupDate = [];
  }

  getInspirationCoverImage(id: string | null) {
    return environment.apiUrl + '/api/public/inspirations/' + id + '/cover';
  }

  getInspirationDraftCoverImage(id: string | null) {
    return (
      environment.apiUrl + '/api/public/inspirations/draft/' + id + '/cover'
    );
  }

  onPublishView(id: string) {
    this.router.navigate(['/inspiration/view', id]);
  }
}
