import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { InspirationService } from 'src/app/main/services/inspiration.service';

@Component({
  selector: 'app-send-newsletter-list',
  templateUrl: './send-newsletter-list.component.html',
})
export class SendNewsletterListComponent {
  isLoading = false;

  selectedButton = 'sent';

  currentPage = 1;

  groupDate: any = [];

  selectButtonOptions: { label: string; value: string; icon: string }[] = [];

  constructor(
    private messageService: MessageService,
    private router: Router,
    private route: ActivatedRoute,
    private inspirationService: InspirationService,
  ) {
    this.selectButtonOptions = [
      { label: 'Sent', value: 'sent', icon: 'task' },
      { label: 'Scheduled', value: 'scheduled', icon: 'schedule' },
      { label: 'Draft', value: 'draft', icon: 'edit_document' },
    ];
  }

  onSelectAction(e: any) {
    const { value } = e;
    this.currentPage = 1;
    this.groupDate = [];
    this.isLoading = true;
  }

  redirect() {
    this.inspirationService.initNewsLetter().subscribe({
      next: (data: any) => {
        this.router.navigate(['/inspiration', 'send', 'newsletter'], {
          queryParams: { draftId: data.id },
        });
      },
      error: (err) => {
        const { errors = null } = err?.error || {};
        this.messageService.add({
          severity: 'error',
          summary: 'Init Newsletter',
          detail: errors ? errors[0] : 'Something went wrong',
        });
      },
    });
  }

  onScrolledDown() {}

  getKey(k: any) {
    return Object.keys(k)[0];
  }

  getValue(k: any) {
    return Object.values(k)[0];
  }
}
