<div class="surface-ground inspiration-main relative">
  <div class="">
    <div class="linear max mx-auto transition-all duration-500">
      <section class="surface-overlay sticky top-0 z-[200]">
        <div
          class="flex flex-wrap items-center justify-between gap-3 p-4 md:gap-0"
        >
          <div
            class="flex cursor-pointer items-center gap-2"
            (click)="op.toggle($event)"
          >
            <p-avatar
              styleClass="mr-2 block"
              class="hidden sm:block"
              size="large"
              shape="circle"
            >
              <img
                *ngIf="user.profilePicUrl"
                [src]="profilePic | image | async"
                alt=""
              />
              <img
                *ngIf="!user.profilePicUrl"
                [src]="'../assets/images/user-pic-default.svg'"
                alt=""
              />
            </p-avatar>

            <div class="flex flex-col">
              <div class="font-semibold">
                <span *ngIf="publishas">{{
                  publishas.ofType === "USER"
                    ? publishas.ofObject.displayName
                    : publishas.ofObject.name
                }}</span>
                <span class="material-icons inline-block align-bottom"
                  >arrow_drop_down</span
                >
              </div>
              <span class="h-level-5" *ngIf="publicationto">{{
                publicationto.title
              }}</span>
            </div>
          </div>
          <div id="toolbar" class="order-2 rounded-md md:order-none">
            <span class="ql-formats">
              <select class="ql-header" name="">
                <option value="2">Heading</option>
                <option value="3">Subheading</option>
                <option selected>Normal</option>
              </select>
            </span>
            <span class="ql-formats"
              ><button
                type="button"
                class="ql-bold"
                aria-pressed="false"
                aria-label="bold"
              ></button
              ><button
                type="button"
                class="ql-italic"
                aria-pressed="false"
                aria-label="italic"
              ></button
              ><button
                type="button"
                class="ql-underline"
                aria-pressed="false"
                aria-label="underline"
              ></button
            ></span>
            <span class="ql-formats">
              <button
                type="button"
                class="ql-list"
                value="ordered"
                aria-label="Ordered List"
              ></button>
              <button
                type="button"
                class="ql-list"
                value="bullet"
                aria-label="Bullet List"
              ></button>
              <select class="ql-align" aria-label="Text Alignment"></select>
            </span>
            <span class="ql-formats">
              <button
                type="button"
                class="ql-link"
                aria-pressed="false"
                aria-label="link"
              ></button
              ><button
                type="button"
                class="ql-image"
                aria-pressed="false"
                aria-label="image"
              ></button
              ><button
                type="button"
                class="ql-video"
                aria-pressed="false"
                aria-label="video"
              ></button>
            </span>
          </div>
          <div class="flex items-center gap-3">
            <div class="relative">
              <button
                type="button"
                pButton
                (click)="managePanel.toggle($event)"
                class="mp-button mp-button-outlined mb-0 flex truncate rounded-md px-5 py-2 transition-all lg:w-auto"
              >
                <span class="hidden sm:block">Manage</span>
                <span class="material-icons block sm:hidden">settings</span>
                <span class="material-icons">arrow_drop_down</span>
              </button>
              <div #manageContainer></div>
            </div>
            <div class="">
              <button
                type="button"
                pButton
                (click)="onSubmit()"
                class="mp-button mp-button-filled mb-0 flex truncate rounded-md px-5 py-2 transition-all lg:w-auto"
              >
                <span>Next</span>
                <span class="material-icons">arrow_forward</span>
              </button>
            </div>
          </div>
        </div>
        <button type="button" id="quill-image-button" class="hidden">
          Image
        </button>
      </section>
      <div class="surface-ground flex flex-col p-2">
        <p-card styleClass="rounded-md huddle-view padding-clear h-full">
          <section class="mx-auto max-w-[780px]">
            <div class="linear max mx-auto p-2 transition-all duration-500">
              <div class="my-4 px-2">
                <label
                  id="headline__textarea"
                  aria-hidden="true"
                  class="no-wrap absolute h-[1px] w-[1px] overflow-hidden border-0 p-0"
                  >Title</label
                >
                <textarea
                  #textarea
                  pTextArea
                  class="h-level-3 unread w-full resize-none border-0 bg-transparent p-0 !font-semibold font-semibold shadow-none outline-none"
                  rows="1"
                  maxlength="150"
                  placeholder="Untitle Inspiration"
                  [(ngModel)]="title"
                  (ngModelChange)="onTextAreaChange($event)"
                ></textarea>
              </div>
              <div class="my-2 px-2">
                <p-editor
                  #inspirationEditor
                  class="flex-1"
                  styleClass="post-editor inspiration-editor min-h-[250px]"
                  placeholder="What's on your mind"
                  [modules]="{ toolbar: '#toolbar' }"
                  (onInit)="onEditorInit($event)"
                >
                </p-editor>
              </div>
            </div>
          </section>
        </p-card>
      </div>
    </div>
  </div>
</div>

<p-overlayPanel styleClass="w-[340px]" #op>
  <div class="flex grow flex-col gap-y-4 overflow-y-auto">
    <div class="flex flex-1 flex-col">
      <h4 class="mb-3 font-semibold">Publish as</h4>
      <ul
        role="list"
        class="flex flex-1 flex-col transition ease-in-out [&>:not(:first-child)]:mt-2"
      >
        <li *ngFor="let publisher of publishers.slice(0, 2)">
          <div class="flex cursor-pointer items-center gap-2">
            <p-avatar styleClass="mr-2 block" size="large" shape="circle">
              <img
                *ngIf="publisher.ofObject.profilePicUrl"
                [src]="profilePic | image | async"
                alt=""
              />
              <img
                *ngIf="!publisher.ofObject.profilePicUrl"
                [src]="'../assets/images/user-pic-default.svg'"
                alt=""
              />
            </p-avatar>
            <div class="flex flex-1 flex-col">
              <label [for]="'publish_as_' + publisher.id">
                <div class="h-level-4">
                  <span>{{
                    publisher.ofType === "USER"
                      ? publisher.ofObject.displayName
                      : publisher.ofObject.name
                  }}</span>
                </div>
              </label>
            </div>
            <div>
              <p-radioButton
                [inputId]="'publish_as_' + publisher.id"
                styleClass=""
                [value]="publisher"
                (onClick)="onPublisherChange($event)"
                [(ngModel)]="publishas"
              ></p-radioButton>
            </div>
          </div>
        </li>
        <li>
          <div class="flex cursor-pointer items-center justify-end gap-2">
            <p-button
              class="contents"
              label="See More"
              styleClass="primary"
              [text]="true"
              (click)="seeMore(0)"
            ></p-button>
          </div>
        </li>
      </ul>
      <p-divider></p-divider>
      <h4 class="mb-3 font-semibold">Publish to</h4>
      <ul
        role="list"
        class="flex flex-1 flex-col transition ease-in-out [&>:not(:first-child)]:mt-2"
      >
        <li>
          <div class="flex cursor-pointer items-center gap-2">
            <p-avatar styleClass="mr-2 block flex" size="large" shape="circle">
              <span class="material-icons !text-lg">article</span>
            </p-avatar>
            <div class="flex flex-1 flex-col">
              <label [for]="'newsletter'">
                <div class="">
                  <span>Newsletter</span>
                </div>
              </label>
            </div>
            <div>
              <!-- <p-radioButton
                [inputId]="'ind_article_' + publication.id"
                styleClass=""
                [value]="publication"
                [(ngModel)]="publicationto"
              ></p-radioButton> -->
            </div>
          </div>
        </li>
        <li>
          <div class="flex cursor-pointer items-center justify-end gap-2">
            <p-button
              class="contents"
              label="See More"
              styleClass="primary"
              [text]="true"
              (click)="seeMore(1)"
            ></p-button>
          </div>
        </li>
      </ul>
    </div>
  </div>
</p-overlayPanel>

<p-overlayPanel styleClass="w-[180px]" #managePanel>
  <div class="flex grow flex-col gap-y-4 overflow-y-auto">
    <div class="flex flex-1 flex-col">
      <ul
        role="list"
        class="flex flex-1 flex-col transition ease-in-out [&>:not(:first-child)]:mt-2"
      >
        <li (click)="onTieredMenuItemClick('manage')">
          <div class="flex cursor-pointer items-center gap-2">
            <div class="flex flex-1 flex-col">Discard</div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</p-overlayPanel>

<div class="overlay" *ngIf="isPageLoading">
  <p-progressSpinner styleClass="horizontal-center"></p-progressSpinner>
</div>

<app-dialog
  [visibleChange]="dialogEvent.asObservable()"
  [view]="'INSPIRATION'"
  header="Select Publish as and to"
  [userDetails]="user"
  width="30vw"
  height="30rem"
  [footer]="true"
  (inspirationData)="publishData($event)"
></app-dialog>
