<div #inspirationList class="surface-ground relative h-full overflow-auto p-2">
  <div class="linear max mx-auto mb-24 transition-all duration-500">
    <section class="mx-auto max-w-[780px] rounded-md">
      <div class="mb-3 flex flex-row items-center justify-between md:gap-8">
        <p-selectButton
          [options]="selectButtonOptions"
          (onChange)="onSelectAction($event)"
          [(ngModel)]="selectedButton"
          styleClass="mp-selectbutton"
          optionLabel="label"
          optionValue="value"
        >
          <ng-template let-item pTemplate>
            <span class="hidden md:block">{{ item.label }}</span>
            <span class="material-icons block md:hidden">{{ item.icon }}</span>
          </ng-template>
        </p-selectButton>
        <div>
          <button
            type="button"
            pButton
            (click)="redirect()"
            class="mp-button mp-button-outlined h-[2.25rem] truncate rounded-md px-5 py-2 transition-all lg:mb-0 lg:w-auto"
          >
            <span>Create</span>
          </button>
        </div>
      </div>
      <div class="flex flex-col items-start justify-start py-3">
        <div
          class="search-results w-full"
          infinite-scroll
          [infiniteScrollDistance]="1"
          [infiniteScrollUpDistance]="2"
          [infiniteScrollThrottle]="500"
          (scrolled)="onScrolledDown()"
          [infiniteScrollContainer]="inspirationList"
          [fromRoot]="true"
          *ngIf="!isLoading"
        >
          <div class="mb-8 last:mb-0" *ngFor="let date of groupDate">
            <!-- <h2 class="h-level-2 mb-2 border-b">
                {{ getKey(date) }}
              </h2> -->
            <!-- <div *ngIf="getValue(date)">
                <div
                  class="shipping-item relative shrink grow basis-0 p-[4px]"
                  *ngFor="let inspiration of $any(getValue(date))"
                >
                  <div
                    *ngIf="inspiration.featured"
                    class="ribbon ribbon-top-left"
                  >
                    <span>Featured</span>
                  </div>
                  <p-card
                    class="mx-auto block md:mx-0 md:my-auto"
                    styleClass="padding-clear authored-list"
                  >
                    <div
                      class="grid grid-cols-1 gap-x-2 gap-y-3 p-2 sm:grid-cols-6"
                      [ngClass]="{
                        glow:
                          publishedId === inspiration.id && tabId === queryTab,
                      }"
                    >
                      <div
                        class="col-span-6 cursor-pointer md:col-span-3"
                        (click)="onPublishView('' + inspiration.id)"
                      >
                        <img
                          *ngIf="
                            tabId === 'published' && inspiration.coverImageId
                          "
                          [src]="
                            getInspirationCoverImage(inspiration.id)
                              | image
                              | async
                          "
                          alt="cropped image"
                          class="h-full w-full rounded-md object-contain object-top"
                        />
                        <img
                          *ngIf="
                            (tabId === 'scheduled' || tabId === 'draft') &&
                            inspiration.coverImageId
                          "
                          [src]="
                            getInspirationDraftCoverImage(inspiration.id)
                              | image
                              | async
                          "
                          alt="cropped image"
                          class="h-full w-full rounded-md object-contain object-top"
                        />
                        <img
                          *ngIf="!inspiration.coverImageId"
                          src="../assets/images/inspiration-default-cover.svg"
                          alt="cropped image"
                          class="h-full w-full rounded-md object-contain object-top"
                        />
                      </div>
                      <div class="col-span-6 md:col-span-3">
                        <div class="flex h-full flex-col">
                          <div class="mb-3 flex items-center justify-between">
                            <div class="inline-flex">
                              <span class="h-level-4 primary mr-3">{{
                                publishedInMap[inspiration.categoryPrimary]
                              }}</span>
                            </div>
                            <button
                              pButton
                              (click)="toggleMenu($event, inspiration)"
                              class="prevent mx-3 h-[32px] rounded-full p-1"
                            >
                              <span class="material-icons prevent">
                                more_horiz
                              </span>
                            </button>
                          </div>
                          <span class="h-level-3 mb-3 block">{{
                            inspiration.title
                          }}</span>
                          <p
                            class="mb-2 flex-1 "
                            *ngIf="inspiration.snippet"
                          >
                            {{ inspiration.snippet.slice(0, 180) }}
                            <span *ngIf="!showSnippet[inspiration.id]">...</span>
                            <span *ngIf="showSnippet[inspiration.id]">
                              {{
                                inspiration.snippet.slice(
                                  180,
                                  inspiration.snippet.length
                                )
                              }}
                            </span>
                            <span
                              class="primary cursor-pointer"
                              (click)="showTruncateText(inspiration.id)"
                              >{{
                                !showSnippet[inspiration.id] ? "more" : "less"
                              }}</span
                            >
                          </p>
                          <div class="flex justify-between">
                            <span class="h-level-5 primary font-semibold">
                              {{
                                tabId === "published"
                                  ? "Published on"
                                  : tabId === "scheduled"
                                    ? "Scheduled to be published on"
                                    : "Drafted on"
                              }}
                              {{
                                (tabId === "published"
                                  ? inspiration.publishDate
                                  : tabId === "draft"
                                    ? inspiration.draftDate
                                    : inspiration.scheduleDate
                                ) | date: "medium"
                              }}
                            </span>
                            <span class="h-level-5 primary font-semibold">
                              {{ visibilityMap[inspiration.visibility] }}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </p-card>
                </div>
              </div> -->
            <div
              *ngIf="!$any(getValue(date))?.length"
              class="mt-2 flex items-center gap-3"
            >
              <span class="material-icons">calendar_today</span>
              <span>No matches found.</span>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</div>

<!-- <p-tieredMenu
    #inspirationMenu
    [model]="inspirationOptions"
    appendTo="body"
    [popup]="true"
    styleClass="p-0"
  >
    <ng-template pTemplate="p-menuitem" let-obj>
      <div
        (click)="onTieredMenuClick(obj.id)"
        class="flex cursor-pointer px-2 py-3"
        *ngIf="
          obj.item === tabId && obj.id !== 'feature' && obj.id !== 'unfeature'
        "
        [ngClass]="{ 'pointer-events-none opacity-80': obj?.disabled }"
      >
        <div class="font-normal">
          <a>{{ obj.label }}</a>
        </div>
      </div>
  
      <div
        (click)="onTieredMenuClick(obj.id)"
        class="flex cursor-pointer px-2 py-3"
        *ngIf="obj.item === tabId && obj.id === 'feature'"
        [ngClass]="{ 'pointer-events-none opacity-80': obj?.disabled }"
      >
        <div class="font-normal">
          <a>{{ !feature ? obj.label : "Unfeature" }}</a>
        </div>
      </div>
    </ng-template>
  </p-tieredMenu>
  
  <div class="overlay" *ngIf="isLoading">
    <p-progressSpinner styleClass="horizontal-center"></p-progressSpinner>
  </div>
  
  <p-dialog
    [(visible)]="scheduleVisible"
    [modal]="true"
    [style]="{ width: '33vw' }"
    [breakpoints]="{ '960px': '95vw' }"
    [appendTo]="'body'"
  >
    <ng-template pTemplate="header">
      <span class="h-level-2 font-bold">Reschedule Publish</span>
    </ng-template>
    <app-datepicker
      [showTime]="true"
      [event]="dateTimePickerEvent.asObservable()"
      datepickerLabel="Start Date"
      timerLabel="Start Time"
      (selectTime)="onTimeSelect($event)"
      (selectDate)="onDateSelect($event)"
    ></app-datepicker>
    <ng-template pTemplate="footer">
      <div class="float-right">
        <button
          type="button"
          pButton
          (click)="onReScheduled()"
          [disabled]="isLoading"
          class="mp-button mp-button-filled flex truncate rounded-md px-5 py-2 transition-all lg:mb-0 lg:w-auto"
        >
          <span>{{ isLoading ? "Rescheduling..." : "Reschedule" }}</span>
        </button>
      </div>
    </ng-template>
  </p-dialog>
  
  <app-sharelink
    *ngIf="inspirationId"
    [event]="shareLinkEvent.asObservable()"
    objectType="INSPIRATION"
    [objectId]="inspirationId"
  ></app-sharelink> -->
