<div class="surface-ground relative h-full overflow-auto p-2">
  <div class="linear max mx-auto mb-24 transition-all duration-500">
    <section class="surface-overlay rounded-md px-2">
      <div
        class="mx-auto flex max-w-[1120px] flex-col items-start justify-start py-3"
      >
        <app-shared-inspiration class="w-full"></app-shared-inspiration>
      </div>
    </section>
  </div>
</div>
