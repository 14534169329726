import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { MessageService } from 'primeng/api';
import { Editor, EditorInitEvent } from 'primeng/editor';
import { Observable, tap } from 'rxjs';
import { InspirationService } from 'src/app/main/services/inspiration.service';
import { PlatformService } from 'src/app/main/services/platform.service';
import { AppState } from 'src/app/main/store/model/state.model';
import { App, Publisher } from 'src/app/main/types/main.types';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-publisher-profile',
  templateUrl: './publisher-profile.component.html',
})
export class PublisherProfileComponent implements OnInit {
  publisherProfileFormGroup!: FormGroup;

  publisherId: string | null;

  isDark = false;

  isLoading = false;

  publisherProfile: Publisher;

  maxLength = 250;

  hasDescription = false;

  editorRef: any;

  appState$: Observable<App>;

  @ViewChild('descriptionPreview') descriptionRef: ElementRef;

  constructor(
    private formBuilder: FormBuilder,
    private messageService: MessageService,
    private inspirationService: InspirationService,
    private route: ActivatedRoute,
    private router: Router,
    private store: Store<AppState>,
    private platformService: PlatformService,
  ) {
    this.publisherId = this.route.snapshot.paramMap.get('publisherId');
  }

  ngOnInit(): void {
    this.getAppState();
    this.createFormGroup();
    this.getPublisherProfile();
  }

  onSubmit() {
    this.isLoading = true;
    this.updatePublisherProfile();
  }

  getAppState() {
    this.appState$ = this.store
      .select((store) => store.app)
      .pipe(
        tap((appState: App) => {
          this.isDark = appState.isDark;
        }),
      );
    this.appState$.subscribe();
  }

  createFormGroup() {
    this.publisherProfileFormGroup = this.formBuilder.group({
      title: [{ value: '', disabled: true }],
      intro: [null],
      description: [null],
    });
  }

  getPublisherProfile() {
    this.inspirationService.getPublishersProfile(this.publisherId).subscribe({
      next: (data: any) => {
        const parentNode = this.descriptionRef.nativeElement.parentNode;
        this.publisherProfile = data;
        this.publisherProfileFormGroup.get('title')?.patchValue(data.title);
        this.publisherProfileFormGroup.get('intro')?.patchValue(data.intro);
        this.editorRef.root.innerHTML = data.description;
        this.descriptionRef.nativeElement.innerHTML = data.description;
        parentNode.style.display = data.description ? 'block' : 'none';
      },
      error: (err) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Publisher Profile',
          detail: err.error.message,
        });
        this.isLoading = false;
      },
    });
  }

  updatePublisherProfile() {
    this.inspirationService
      .updatePublisherProfile(this.publisherId, {
        ...this.publisherProfileFormGroup.value,
        id: this.publisherProfile.id,
        ofId: this.publisherProfile.ofId,
        ofType: this.publisherProfile.ofType,
      })
      .subscribe({
        next: (data: any) => {
          console.log(data);
          this.isLoading = false;
          this.redirect(['/inspiration', 'publishers']);
        },
        error: (err) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Publisher Profile',
            detail: err.error.message,
          });
          this.isLoading = false;
        },
      });
  }

  redirect(path: string[]) {
    this.router.navigate(path);
  }

  reset() {
    this.publisherProfileFormGroup
      .get('title')
      ?.patchValue(this.publisherProfile.title);
    this.publisherProfileFormGroup
      .get('intro')
      ?.patchValue(this.publisherProfile.intro);
    this.publisherProfileFormGroup
      .get('description')
      ?.patchValue(this.publisherProfile.description);
  }

  async onEditorInit(e: EditorInitEvent) {
    if (this.platformService.getIsBrowser()) {
      const { Delta } = await import('quill/core');
      this.editorRef = e.editor;
      e.editor.clipboard.addMatcher(
        Node.ELEMENT_NODE,
        function (node: HTMLElement) {
          return new Delta().insert(node.innerText);
        },
      );
    }
  }

  textChange(e: any) {
    const parentNode = this.descriptionRef.nativeElement.parentNode;
    this.descriptionRef.nativeElement.innerHTML = e.htmlValue;
    parentNode.style.display = e.textValue !== '' ? 'block' : 'none';
    if (this.editorRef.getLength() > this.maxLength) {
      this.editorRef.deleteText(this.maxLength, this.editorRef.getLength());
    }
  }

  getProfileImage(id: number) {
    return environment.apiUrl + '/api/user/' + id + '/pic';
  }
}
