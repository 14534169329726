import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';
import { InspirationComponent } from './inspiration.component';
import { InspirationOverviewComponent } from './inspiration-overview/inspiration-overview.component';
import { InspirationFollowingComponent } from './inspiration-following/inspiration-following.component';
import { InspirationBookmarkComponent } from './inspiration-bookmark/inspiration-bookmark.component';
import { InspirationSharedComponent } from './inspiration-shared/inspiration-shared.component';
import { InspirationAuthoredComponent } from './inspiration-authored/inspiration-authored.component';
import { InspirationViewComponent } from './inspiration-view/inspiration-view.component';
import { InspirationCreateComponent } from './inspiration-create/inspiration-create.component';
import { InspirationPreviewComponent } from './inspiration-preview/inspiration-preview.component';
import { PublisherOverviewComponent } from './publisher-overview/publisher-overview.component';
import { PublisherProfileComponent } from './publisher-profile/publisher-profile.component';
import { SendNewsletterCreateComponent } from './send-newsletter/send-newsletter-create/send-newsletter-create.component';
import { SendNewsletterListComponent } from './send-newsletter/send-newsletter-list/send-newsletter-list.component';
import { NewsletterAndSubscribersComponent } from './newsletter-and-subscribers/newsletter-and-subscribers.component';
import { SendNewsletterPreviewComponent } from './send-newsletter/send-newsletter-preview/send-newsletter-preview.component';
import { authGuard } from '../../utilities';

const routes: Routes = [
  {
    path: '',
    canActivate: [authGuard],
    component: InspirationComponent,
    children: [
      {
        path: '',
        component: InspirationOverviewComponent,
      },
      {
        path: 'following',
        component: InspirationFollowingComponent,
      },
      {
        path: 'bookmark',
        component: InspirationBookmarkComponent,
      },
      {
        path: 'shared',
        component: InspirationSharedComponent,
      },
      {
        path: 'authored',
        component: InspirationAuthoredComponent,
      },
      {
        path: 'edit',
        component: InspirationCreateComponent,
      },
      {
        path: 'view/:id',
        component: InspirationViewComponent,
      },
      {
        path: 'preview/:draftId',
        component: InspirationPreviewComponent,
      },
      {
        path: 'publishers',
        component: PublisherOverviewComponent,
      },
      {
        path: 'publishers/:publisherId',
        component: PublisherProfileComponent,
      },
      {
        path: 'send/newsletter',
        component: SendNewsletterCreateComponent,
      },
      {
        path: 'newsletter/list',
        component: SendNewsletterListComponent,
      },
      {
        path: 'newsletterandsubscribers',
        component: NewsletterAndSubscribersComponent,
      },
      {
        path: 'send/newsletter/preview',
        component: SendNewsletterPreviewComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class InspirationRoutingModule {}
