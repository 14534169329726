import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { MessageService } from 'primeng/api';
import { Observable, tap } from 'rxjs';
import { InspirationService } from 'src/app/main/services/inspiration.service';
import { AppState } from 'src/app/main/store/model/state.model';
import { Publisher, UserDetails } from 'src/app/main/types/main.types';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-publisher-overview',
  templateUrl: './publisher-overview.component.html',
})
export class PublisherOverviewComponent implements OnInit {
  currentPage = 1;

  isLoading = false;

  userInfo: UserDetails;

  userDetail$: Observable<UserDetails>;

  publishersPersonalProfile: Publisher[] = [];

  publishersPageProfile: Publisher[] = [];

  constructor(
    private inspirationService: InspirationService,
    private messageService: MessageService,
    private router: Router,
    private store: Store<AppState>,
  ) {}

  ngOnInit(): void {
    this.getUserInfo();
    this.getPublisher();
  }

  getUserInfo() {
    this.userDetail$ = this.store
      .select((store) => store.user)
      .pipe(
        tap((user: UserDetails) => {
          this.userInfo = user;
        }),
      );
    this.userDetail$.subscribe();
  }

  getPublisher() {
    this.inspirationService.getPublishers().subscribe({
      next: (data: any) => {
        this.publishersPersonalProfile = data.filter(
          (p: Publisher) => p.ofType === 'USER',
        );
        this.publishersPageProfile = data.filter(
          (p: Publisher) => p.ofType === 'PAGE',
        );
      },
      error: (err) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Publisher',
          detail: err.error.message,
        });
        this.isLoading = false;
      },
    });
  }

  getProfileImage(id: number) {
    return environment.apiUrl + '/api/user/' + id + '/pic';
  }

  redirect(id: number) {
    this.router.navigate(['/inspiration', 'publishers', id]);
  }
}
