<div class="surface-ground inspiration-main relative">
  <div class="">
    <div class="linear max mx-auto transition-all duration-500">
      <section class="surface-overlay sticky top-0 z-[200]">
        <div
          class="flex flex-wrap items-center justify-between gap-3 p-4 md:gap-0"
        >
          <div
            class="flex cursor-pointer items-center gap-2"
            (click)="op.toggle($event)"
          >
            <p-avatar
              styleClass="mr-2 block"
              class="hidden sm:block"
              size="large"
              shape="circle"
            >
              <img
                *ngIf="user.profilePicUrl"
                [src]="profilePic | image | async"
                alt=""
              />
              <img
                *ngIf="!user.profilePicUrl"
                [src]="'../assets/images/user-pic-default.svg'"
                alt=""
              />
            </p-avatar>

            <div class="flex flex-col">
              <div class="font-semibold">
                <span *ngIf="publishas">{{
                  publishas.ofType === "USER"
                    ? publishas.ofObject.displayName
                    : publishas.ofObject.name
                }}</span>
                <span class="material-icons inline-block align-bottom"
                  >arrow_drop_down</span
                >
              </div>
              <span class="h-level-5" *ngIf="publicationto">{{
                publicationto.title
              }}</span>
            </div>
          </div>
          <div id="toolbar" class="order-2 rounded-md md:order-none">
            <span class="ql-formats">
              <select class="ql-header" name="">
                <option value="2">Heading</option>
                <option value="3">Subheading</option>
                <option selected>Normal</option>
              </select>
            </span>
            <span class="ql-formats"
              ><button
                type="button"
                class="ql-bold"
                aria-pressed="false"
                aria-label="bold"
              ></button
              ><button
                type="button"
                class="ql-italic"
                aria-pressed="false"
                aria-label="italic"
              ></button
              ><button
                type="button"
                class="ql-underline"
                aria-pressed="false"
                aria-label="underline"
              ></button
            ></span>
            <span class="ql-formats">
              <button
                type="button"
                class="ql-list"
                value="ordered"
                aria-label="Ordered List"
              ></button>
              <button
                type="button"
                class="ql-list"
                value="bullet"
                aria-label="Bullet List"
              ></button>
              <select class="ql-align" aria-label="Text Alignment"></select>
            </span>
            <span class="ql-formats">
              <button
                type="button"
                class="ql-link"
                aria-pressed="false"
                aria-label="link"
              ></button
              ><button
                type="button"
                class="ql-image"
                aria-pressed="false"
                aria-label="image"
              ></button
              ><button
                type="button"
                class="ql-video"
                aria-pressed="false"
                aria-label="video"
              ></button>
              <button
                type="button"
                class="ql-map"
                aria-pressed="false"
                aria-label="map"
                (click)="openMapDialog()"
              >
                <span class="material-icons block !text-[1.2em] opacity-80"
                  >map</span
                >
              </button>
            </span>
          </div>
          <div class="flex items-center gap-3">
            <div class="relative">
              <button
                type="button"
                pButton
                (click)="managePanel.toggle($event)"
                class="mp-button mp-button-outlined mb-0 flex truncate rounded-md px-5 py-2 transition-all lg:w-auto"
              >
                <span class="hidden sm:block">Manage</span>
                <span class="material-icons block sm:hidden">settings</span>
                <span class="material-icons">arrow_drop_down</span>
              </button>
              <div #manageContainer></div>
            </div>
            <div class="">
              <button
                type="button"
                pButton
                (click)="onSubmit()"
                class="mp-button mp-button-filled mb-0 flex truncate rounded-md px-5 py-2 transition-all lg:w-auto"
              >
                <span>Next</span>
                <span class="material-icons">arrow_forward</span>
              </button>
            </div>
          </div>
        </div>
        <button
          type="button"
          id="quill-image-button"
          class="hidden"
          (click)="quillImageVisible = true"
        >
          Image
        </button>
      </section>
      <div class="surface-ground flex flex-col p-2">
        <p-card styleClass="rounded-md huddle-view padding-clear h-full">
          <section class="mx-auto max-w-[780px]">
            <div class="linear max mx-auto p-2 transition-all duration-500">
              <div
                class="relative aspect-video h-52 rounded-md bg-gray-400 pt-[56.25%]"
              >
                <div class="absolute bottom-0 left-0 right-0 top-0">
                  <img
                    *ngIf="!croppedImage && !hasCoverImage"
                    src="../assets/images/inspiration-default-cover.svg"
                    alt="cropped image"
                    class="h-full w-full rounded-md"
                  />
                  <img
                    *ngIf="hasCoverImage && !croppedImage"
                    [src]="
                      getInspirationDraftCoverImage(draftId) | image | async
                    "
                    alt="cropped image"
                    class="h-full w-full rounded-md object-contain object-center"
                  />
                  <div class="flex flex-1 rounded-md">
                    <p-button
                      *ngIf="!hasCoverImage"
                      [outlined]="true"
                      styleClass="px-5 py-2 rounded-md absolute right-3 bottom-3 mp-button mp-button-filled hidden md:flex"
                    >
                      <span class="material-icons inline-block align-middle"
                        >add_a_photo</span
                      >
                      <label
                        for="file-upload"
                        class="relative cursor-pointer rounded-md"
                      >
                        <span class="ml-2 font-normal">Photo</span>
                        <input
                          id="file-upload"
                          name="file-upload"
                          type="file"
                          class="sr-only"
                          (change)="fileChangeEvent($event)"
                        />
                      </label>
                    </p-button>
                    <p-button
                      *ngIf="hasCoverImage"
                      [outlined]="true"
                      (click)="editAndRemoveCroppedImage('D')"
                      styleClass="px-5 py-2 rounded-md absolute right-[8rem] bottom-3 mp-button mp-button-filled hidden md:flex"
                    >
                      <label
                        for="file-upload"
                        class="relative flex cursor-pointer items-center rounded-md"
                      >
                        <span class="material-icons">delete</span>
                        <span class="ml-2 hidden md:block">Remove</span>
                      </label>
                    </p-button>
                    <p-button
                      [outlined]="true"
                      (click)="vehicleMenu.toggle($event)"
                      styleClass="px-5 py-2 mp-button mp-button-filled rounded-md text-sm absolute border right-[1.25rem] md:right-[7rem] bottom-3 flex md:hidden"
                    >
                      <span class="material-icons">photo_camera</span>
                      <input
                        #upload
                        id="menu-file-upload"
                        name="file-upload"
                        type="file"
                        class="sr-only"
                        (change)="fileChangeEvent($event)"
                      />
                    </p-button>
                    <p-button
                      *ngIf="hasCoverImage"
                      [outlined]="true"
                      styleClass="px-5 py-2 rounded-md absolute right-3 bottom-3 mp-button mp-button-filled hidden md:flex"
                    >
                      <label
                        for="file-upload-edit"
                        class="relative flex cursor-pointer items-center rounded-md"
                      >
                        <span class="material-icons">add_a_photo</span>
                        <span class="ml-2 hidden md:block">Edit</span>
                        <input
                          id="file-upload-edit"
                          name="file-upload-edit"
                          type="file"
                          class="sr-only"
                          (change)="fileChangeEvent($event)"
                        />
                      </label>
                    </p-button>
                  </div>
                </div>
                <div #vehicleButtonMenu></div>
              </div>
              <div class="my-1 px-2">
                <input
                  placeholder="Caption or photo credit"
                  pInputText
                  type="text"
                  id="caption"
                  [disabled]="!hasCoverImage"
                  [(ngModel)]="coverPhotoCaption"
                  class="float-right block h-8 w-3/12 rounded-md pr-1 text-right text-sm focus:ring-0"
                />
              </div>
              <div class="my-4 px-2">
                <label
                  id="headline__textarea"
                  aria-hidden="true"
                  class="no-wrap absolute h-[1px] w-[1px] overflow-hidden border-0 p-0"
                  >Title</label
                >
                <textarea
                  #textarea
                  pTextArea
                  class="h-level-3 unread w-full resize-none border-0 bg-transparent p-0 !font-semibold font-semibold shadow-none outline-none"
                  rows="1"
                  maxlength="150"
                  placeholder="Untitle Inspiration"
                  [(ngModel)]="title"
                  (ngModelChange)="onTextAreaChange($event)"
                ></textarea>
              </div>
              <div class="my-2 px-2">
                <p-editor
                  #inspirationEditor
                  class="flex-1"
                  styleClass="post-editor inspiration-editor min-h-[250px]"
                  placeholder="Feeling inspired? Share your latest adventure!"
                  [modules]="{ toolbar: '#toolbar' }"
                  (onInit)="onEditorInit($event)"
                >
                </p-editor>
              </div>
            </div>
          </section>
        </p-card>
      </div>
    </div>
  </div>
</div>

<p-overlayPanel styleClass="w-[340px]" #op>
  <div class="flex grow flex-col gap-y-4 overflow-y-auto">
    <div class="flex flex-1 flex-col">
      <h4 class="mb-3 font-semibold">Publish as</h4>
      <ul
        role="list"
        class="flex flex-1 flex-col transition ease-in-out [&>:not(:first-child)]:mt-2"
      >
        <li *ngFor="let publisher of publishers.slice(0, 2)">
          <div class="flex cursor-pointer items-center gap-2">
            <p-avatar styleClass="mr-2 block" size="large" shape="circle">
              <img
                *ngIf="publisher.ofObject.profilePicUrl"
                [src]="profilePic | image | async"
                alt=""
              />
              <img
                *ngIf="!publisher.ofObject.profilePicUrl"
                [src]="'../assets/images/user-pic-default.svg'"
                alt=""
              />
            </p-avatar>
            <div class="flex flex-1 flex-col">
              <label [for]="'publish_as_' + publisher.id">
                <div class="h-level-4">
                  <span>{{
                    publisher.ofType === "USER"
                      ? publisher.ofObject.displayName
                      : publisher.ofObject.name
                  }}</span>
                </div>
              </label>
            </div>
            <div>
              <p-radioButton
                [inputId]="'publish_as_' + publisher.id"
                styleClass=""
                [value]="publisher"
                (onClick)="onPublisherChange($event)"
                [(ngModel)]="publishas"
              ></p-radioButton>
            </div>
          </div>
        </li>
        <li>
          <div class="flex cursor-pointer items-center justify-end gap-2">
            <p-button
              class="contents"
              label="See More"
              styleClass="primary"
              [text]="true"
              (click)="seeMore(0)"
            ></p-button>
          </div>
        </li>
      </ul>
      <p-divider></p-divider>
      <h4 class="mb-3 font-semibold">Publish to</h4>
      <ul
        role="list"
        class="flex flex-1 flex-col transition ease-in-out [&>:not(:first-child)]:mt-2"
      >
        <li *ngFor="let publication of publications.slice(0, 2)">
          <div class="flex cursor-pointer items-center gap-2">
            <p-avatar styleClass="mr-2 block flex" size="large" shape="circle">
              <span class="material-icons !text-lg">article</span>
            </p-avatar>
            <div class="flex flex-1 flex-col">
              <label [for]="'ind_article_' + publication.id">
                <div class="">
                  <span>{{ publication.title }}</span>
                </div>
              </label>
            </div>
            <div>
              <p-radioButton
                [inputId]="'ind_article_' + publication.id"
                styleClass=""
                [value]="publication"
                [(ngModel)]="publicationto"
              ></p-radioButton>
            </div>
          </div>
        </li>
        <li>
          <div class="flex cursor-pointer items-center justify-end gap-2">
            <p-button
              class="contents"
              label="See More"
              styleClass="primary"
              [text]="true"
              (click)="seeMore(1)"
            ></p-button>
          </div>
        </li>
      </ul>
    </div>
  </div>
</p-overlayPanel>

<p-tieredMenu
  #vehicleMenu
  [model]="cropperMenuOptions"
  [popup]="true"
  [appendTo]="vehicleButtonMenu"
  styleClass="p-0 !left-[160px] !top-[198.8px]"
>
  <ng-template pTemplate="p-menuitem" let-obj>
    <div
      (click)="onTieredMenuItemClick(obj.action)"
      [ngClass]="
        obj.id === 'image_delete' && !croppedImage
          ? 'pointer-events-none opacity-40'
          : ''
      "
      class="flex cursor-pointer px-2 py-3 dark:bg-[#1F242F] dark:text-slate-300"
    >
      <span class="material-icons-outlined mr-1">{{ obj.icon }}</span>
      <div class="font-normal">
        <a>{{ obj.label }}</a>
      </div>
    </div>
  </ng-template>
</p-tieredMenu>

<p-overlayPanel styleClass="w-[180px]" #managePanel>
  <div class="flex grow flex-col gap-y-4 overflow-y-auto">
    <div class="flex flex-1 flex-col">
      <ul
        role="list"
        class="flex flex-1 flex-col transition ease-in-out [&>:not(:first-child)]:mt-2"
      >
        <li (click)="onTieredMenuItemClick('manage')">
          <div class="flex cursor-pointer items-center gap-2">
            <div class="flex flex-1 flex-col">Discard</div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</p-overlayPanel>

<p-dialog
  [(visible)]="visible"
  [modal]="true"
  [style]="{ width: '50vw' }"
  [breakpoints]="{ '960px': '95vw' }"
  [appendTo]="'body'"
  (onShow)="onCropperDialogShow()"
  styleClass="dark:text-slate-300 dark:bg-[#0C111D] dark:border dark:border-[#1F242F]"
>
  <ng-template pTemplate="header">
    <span class="text-xl font-bold">Crop Image</span>
  </ng-template>
  <image-cropper
    [imageChangedEvent]="imageChangedEvent"
    [maintainAspectRatio]="true"
    [aspectRatio]="ratio"
    (imageCropped)="imageCropped($event)"
    [transform]="transform"
    format="jpeg"
  ></image-cropper>
  <div class="overlay" *ngIf="isLoading">
    <p-progressSpinner styleClass="horizontal-center"></p-progressSpinner>
  </div>
  <ng-template pTemplate="footer">
    <div
      class="flex flex-row-reverse items-center justify-between gap-3 pt-[1.25rem]"
    >
      <div>
        <p-button
          (click)="onCropperClose()"
          label="Cancel"
          styleClass="leading-6 px-5 py-2 rounded-md transition-all mp-button mp-button-outlined mr-2"
        ></p-button>
        <p-button
          (click)="onCropped()"
          label="Crop"
          styleClass="rounded-md px-5 py-2 shadow-sm mp-button mp-button-filled leading-6"
        ></p-button>
      </div>
      <div class="flex gap-2">
        <span
          class="material-icons primary"
          [ngClass]="{
            'pointer-events-none opacity-60': scale > 1.4,
            'cursor-pointer': scale < 1.4,
          }"
          (click)="zoomInAndOut('in')"
          >zoom_in</span
        >
        <span
          class="material-icons primary"
          [ngClass]="{
            'pointer-events-none opacity-60': scale === 1,
            'cursor-pointer': scale > 1,
          }"
          (click)="zoomInAndOut('out')"
          >zoom_out</span
        >
      </div>
    </div>
  </ng-template>
</p-dialog>

<p-dialog
  [(visible)]="quillImageVisible"
  [modal]="true"
  [style]="{ width: '30vw' }"
  [breakpoints]="{ '960px': '95vw' }"
  [appendTo]="'body'"
  (onHide)="dialogEvents('O')"
  styleClass="image-quill-dialog"
>
  <ng-template pTemplate="header">
    <span class="text-xl font-bold">Insert Image</span>
  </ng-template>
  <div class="overlay" *ngIf="isLoading">
    <p-progressSpinner styleClass="horizontal-center"></p-progressSpinner>
  </div>
  <div class="">
    <div class="grid grid-cols-1 gap-x-2 gap-y-4 px-3 py-2 sm:grid-cols-6">
      <div class="col-span-6">
        <label class="form-title block leading-6">Show</label>
        <div class="mt-2 flex gap-4">
          <div *ngFor="let image of images" class="field-checkbox">
            <p-radioButton
              [inputId]="image.key"
              [value]="image.key"
              [(ngModel)]="selectImageType"
              (ngModelChange)="onImageTypeChange($event)"
            ></p-radioButton>
            <label [for]="image.key" class="ml-2">{{ image.name }}</label>
          </div>
        </div>
      </div>
      <div class="col-span-6" *ngIf="selectImageType !== 'SINGLE'">
        <label for="gallery-name" class="form-title block leading-6"
          >Name of the gallery<span class="text-red-400">*</span></label
        >
        <div class="mt-2">
          <input
            placeholder="Gallery Name"
            pInputText
            type="text"
            id="gallery-name"
            [(ngModel)]="galleryName"
            (ngModelChange)="onGalleryNameChange($event)"
            class="block h-12 w-full rounded-md border px-2 py-1.5"
            [ngClass]="hasGalleryNameEmpty ? 'border-red-200' : ''"
          />
        </div>
        <div *ngIf="hasGalleryNameEmpty" class="text-red-500">
          <small>Gallery Name is required</small>
        </div>
      </div>
      <div class="col-span-6">
        <label for="caption-credit" class="form-title block leading-6"
          >Caption or Photo Credit</label
        >
        <div class="mt-2">
          <input
            placeholder="Enter Caption or Photo Credit"
            pInputText
            type="text"
            id="caption-credit"
            [(ngModel)]="caption"
            class="block h-12 w-full rounded-md border px-2 py-1.5"
          />
        </div>
        <div *ngIf="hasGalleryNameEmpty" class="text-red-500">
          <small>Gallery Name is required</small>
        </div>
      </div>
      <div class="col-span-6" *ngIf="quillImages.length">
        <label class="form-title block leading-6"
          >{{ selectImageType === "SINGLE" ? "Image" : "Images "
          }}<span>{{
            selectImageType === "MULTIPLE" ? "(" + quillImages.length + ")" : ""
          }}</span></label
        >
        <div class="mt-2">
          <p-scrollPanel
            [style]="{
              width: '100%',
              maxHeight: '150px',
            }"
            styleClass="quill-image-list !h-auto overflow-auto thin-scrollbar"
          >
            <div class="selected-users rounded-md">
              <ul role="list" class="">
                <li
                  *ngFor="let img of quillImages"
                  class="grow-1 flex-stretch mb-2 flex items-center justify-between px-2 pb-2"
                >
                  <div class="grow-1 flex w-full items-center justify-between">
                    <a [href]="img.fileURL" target="_blank" class="primary">
                      <span>{{ img.fileName }}</span>
                    </a>
                    <p-button
                      [rounded]="true"
                      type="button"
                      (click)="dialogEvents('R', img)"
                      styleClass="justify-center h-6 w-6 mp-button mp-button-rounded"
                    >
                      <span class="material-icons">close</span>
                    </p-button>
                  </div>
                </li>
              </ul>
            </div>
          </p-scrollPanel>
        </div>
      </div>
      <div class="col-span-6">
        <label
          for="image-quill-upload"
          class="form-title primary cursor-pointer"
          [ngClass]="
            selectImageType === 'SINGLE' && quillImages.length === 1
              ? 'pointer-events-none opacity-60'
              : ''
          "
        >
          <p-button
            [rounded]="true"
            type="button"
            styleClass="justify-center h-6 w-6 mp-button mp-button-rounded"
          >
            <span class="material-icons">add</span>
          </p-button>
          <span class="ml-2 font-normal">Add Image</span>
          <input
            *ngIf="selectImageType === 'SINGLE'"
            id="image-quill-upload"
            name="file-upload"
            type="file"
            class="sr-only"
            accept="image/png,image/jpeg,image/jpg,image/webp"
            (change)="quillFileChangeEvent($event)"
          />
          <input
            *ngIf="selectImageType !== 'SINGLE'"
            id="image-quill-upload"
            name="file-upload"
            type="file"
            multiple
            class="sr-only"
            accept="image/png,image/jpeg,image/jpg,image/webp"
            (change)="quillFileChangeEvent($event)"
          />
        </label>
      </div>
    </div>
  </div>
  <ng-template pTemplate="footer">
    <div
      class="flex flex-row-reverse items-center justify-between gap-3 pt-[1.25rem]"
    >
      <div>
        <p-button
          label="Insert"
          (click)="insert()"
          [disabled]="
            ((galleryName === '' && selectImageType === 'SINGLE') ||
              (selectImageType !== 'SINGLE' && galleryName === '')) &&
            quillImages.length === 0
          "
          styleClass="rounded-md px-5 py-2 shadow-sm mp-button mp-button-filled leading-6"
        ></p-button>
      </div>
    </div>
  </ng-template>
</p-dialog>

<p-dialog
  [(visible)]="quillMapVisible"
  [modal]="true"
  [style]="{ width: '30vw' }"
  [breakpoints]="{ '960px': '95vw' }"
  [appendTo]="'body'"
  (onHide)="dialogEvents('M')"
  styleClass="map-quill-dialog"
>
  <ng-template pTemplate="header">
    <span class="text-xl font-bold">Map</span>
  </ng-template>
  <div class="">
    <div class="grid grid-cols-1 gap-x-2 gap-y-4 px-3 py-2 sm:grid-cols-6">
      <div class="col-span-6">
        <label class="form-title block leading-6">Show map as</label>
        <div class="mt-2 flex gap-4">
          <div *ngFor="let map of maps" class="field-checkbox">
            <p-radioButton
              [inputId]="map.key"
              [value]="map.key"
              [(ngModel)]="selectMapType"
              (ngModelChange)="onMapTypeChange($event)"
            ></p-radioButton>
            <label [for]="map.key" class="ml-2">{{ map.name }}</label>
          </div>
        </div>
      </div>

      <div class="col-span-6">
        <label class="form-title block leading-6">Address</label>
        <div class="mt-2" *ngFor="let location of locations; let i = index">
          <div class="relative flex items-center gap-2">
            <span
              *ngIf="
                ['ROUTE', 'LOOP'].includes(selectMapType) &&
                i !== locations.length - 1
              "
              class="basis-[5%]"
            >
              <img
                src="../assets/images/start_location.png"
                class="h-[18px] w-[18px]"
                alt="start point"
              />
            </span>
            <span
              *ngIf="
                ['ROUTE', 'LOOP'].includes(selectMapType) &&
                i === locations.length - 1
              "
              class="basis-[5%]"
            >
              <img
                src="../assets/images/place_outline.png"
                class="h-[18px] w-[18px]"
                alt="place outline"
              />
            </span>
            <span
              *ngIf="
                ['ROUTE', 'LOOP'].includes(selectMapType) &&
                i !== locations.length - 1
              "
              class="absolute left-0 top-[40px]"
            >
              <img
                src="../assets/images/route-3dots.png"
                class="h-[18px] w-[18px]"
                alt="place outline"
              />
            </span>
            <div class="flex-1">
              <input
                #search
                placeholder="Enter Location"
                [id]="location.id"
                pInputText
                type="text"
                [ngClass]="location.error ? 'border-red-200' : ''"
                class="block h-12 w-full rounded-md border px-2 py-1.5"
              />
              <div *ngIf="location.error" class="text-red-500">
                <small>Location is required</small>
              </div>
            </div>
            <div class="basis-[10%]">
              <button
                title=""
                type="button"
                *ngIf="locations.length > 2"
                (click)="addAndRemoveAddress('D', location.id)"
                pButton
                class="mp-button mp-button-rounded h-6 w-6 justify-center rounded-full"
              >
                <span class="material-icons !text-base">close</span>
              </button>
            </div>
          </div>
        </div>
        <div class="mt-4" *ngIf="['ROUTE', 'LOOP'].includes(selectMapType)">
          <button
            title=""
            pButton
            type="button"
            (click)="addAndRemoveAddress('A')"
            class="mp-button mp-button-filled rounded-md px-5 py-2 leading-6 shadow-sm"
          >
            <span class="material-icons">add</span>
            Add Address
          </button>
        </div>
        <div class="quill-map__wrapper">
          <div
            [id]="'quill-map__location'"
            style="height: 250px; margin-top: 8px"
          ></div>
        </div>
      </div>
    </div>
  </div>
  <ng-template pTemplate="footer">
    <div
      class="flex flex-row-reverse items-center justify-between gap-3 pt-[1.25rem]"
    >
      <div>
        <p-button
          label="Insert"
          (click)="insertMap()"
          styleClass="rounded-md px-5 py-2 shadow-sm mp-button mp-button-filled leading-6"
        ></p-button>
      </div>
    </div>
  </ng-template>
</p-dialog>
<div class="overlay" *ngIf="isPageLoading">
  <p-progressSpinner styleClass="horizontal-center"></p-progressSpinner>
</div>

<app-dialog
  [visibleChange]="dialogEvent.asObservable()"
  [view]="'INSPIRATION'"
  header="Select Publish as and to"
  [userDetails]="user"
  width="30vw"
  height="30rem"
  [footer]="true"
  (inspirationData)="publishData($event)"
></app-dialog>
