import {
  Component,
  ElementRef,
  ViewChild,
  OnInit,
  AfterViewInit,
  Inject,
  OnDestroy,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import {
  ImageTransform,
  CropperPosition,
  ImageCroppedEvent,
} from 'ngx-image-cropper';
import { InspirationService } from 'src/app/main/services/inspiration.service';
import { MessageService } from 'primeng/api';
import {
  initMap,
  validateImage,
  setAdvancedMarker,
  setMapCenter,
  setMapZoom,
  emptyMarkers,
  removeMarkers,
} from 'src/app/main/utilities';
import {
  cropperMenuOptions,
  inspirationOptions,
} from 'src/app/main/utilities/overlayHelper';
import { Editor } from 'primeng/editor';
import {
  InspirationDraft,
  UserDetails,
  Galleries,
  App,
  DialogEvent,
  Publisher,
  Publication,
} from 'src/app/main/types/main.types';
import { Observable, Subject, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/main/store/model/state.model';
import {
  quillImageHandler,
  quillMapHandler,
  quillImageSlider,
} from 'src/app/main/utilities';
import { DOCUMENT } from '@angular/common';
// import { Delta } from 'quill/core';
import { TieredMenu } from 'primeng/tieredmenu';
import { UserService } from 'src/app/main/services/user.service';
import { PlatformService } from 'src/app/main/services/platform.service';

enum ImageGrid {
  S = 'SINGLE',
  M = 'MULTIPLE',
}

enum MapType {
  P = 'POINT',
  R = 'ROUTE',
  L = 'LOOP',
}

@Component({
  selector: 'app-inspiration-create',
  templateUrl: './inspiration-create.component.html',
})
export class InspirationCreateComponent implements OnInit, OnDestroy {
  isDark = false;
  isLoading = false;
  isPageLoading = false;
  responsiveOptions: any[] | undefined;
  croppedImage: any = null;
  cropperMenuOptions = cropperMenuOptions;
  inspirationOptions = inspirationOptions;
  file: File;
  sourceImage: any = '';
  imageChangedEvent: any;
  visible = false;
  scale = 1;
  ratio: number = 16 / 9;
  transform: ImageTransform = {};
  cropperPosition: CropperPosition;
  blob: Blob | null | undefined;
  quill: any;
  title: string;
  draft: InspirationDraft;
  draftId: string | null;
  user: UserDetails;
  profilePic = '';
  images: { name: string; key: string; formName: string }[];
  maps: { name: string; key: string }[];
  quillImageVisible = false;
  quillMapVisible = false;
  selectImageType = ImageGrid.S;
  selectMapType = MapType.P;
  galleryName = '';
  galleryNameKey = '';
  isGalleryEdit = false;
  quillImages: Galleries[] = [];
  formData: any;
  filesMap: any;
  galleries: Record<string, Galleries[]> = {};
  hasCoverImage = false;
  hasGalleryNameEmpty = false;
  latLng: google.maps.LatLng | null;
  map: google.maps.Map | null;
  directionRenderer: google.maps.DirectionsRenderer | null;
  userDetail$: Observable<UserDetails>;
  wrapperMapId = 'location';
  locations: { id: string; error?: boolean }[] = [];
  waypoints: google.maps.DirectionsWaypoint[];
  titlePlaceholder = '';
  contentPlaceholder = '';
  caption = '';
  coverPhotoCaption = '';
  isBrowser = true;
  sourceAndDestination: {
    source: string;
    destination: string;
    latlng: google.maps.LatLng | null;
  } = { source: '', destination: '', latlng: null };
  appState$: Observable<App>;
  publishers: Publisher[] = [];
  publications: Publication[] = [];
  publishas: Publisher | null;
  publicationto: Publication;
  dialogEvent: Subject<DialogEvent> = new Subject<DialogEvent>();
  @ViewChild('upload') uploadRef: ElementRef;
  @ViewChild('textarea') textAreaRef: ElementRef;
  @ViewChild('manage', { static: false }) manageRef: TieredMenu;
  @ViewChild('inspirationEditor', { static: false }) editorRef: Editor;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private messageService: MessageService,
    private inspirationService: InspirationService,
    private userService: UserService,
    private store: Store<AppState>,
    private platformService: PlatformService,
    @Inject(DOCUMENT) private document: Document,
  ) {
    this.images = [
      { name: 'Single Image', key: ImageGrid.S, formName: 'image' },
      {
        name: 'Multiple images as gallery',
        key: ImageGrid.M,
        formName: 'image',
      },
    ];
    this.maps = [
      { name: 'Point', key: MapType.P },
      { name: 'Route', key: MapType.R },
      { name: 'Loop', key: MapType.L },
    ];
  }

  ngOnInit(): void {
    this.draftId = this.route.snapshot.queryParamMap.get('draftId');
    if (this.draftId) {
      this.getDraftedInspiration(this.draftId);
    }

    this.userDetail$ = this.store
      .select((store) => store.user)
      .pipe(
        tap((user: UserDetails) => {
          this.user = user;
          this.profilePic =
            environment.apiUrl + '/api/user/' + this.user.id + '/pic';
        }),
      );
    this.userDetail$.subscribe();
    this.appState$ = this.store
      .select((store) => store.app)
      .pipe(
        tap((appState: App) => {
          this.isDark = appState.isDark;
          this.changeKeenSlideTheme(appState.isDark);
        }),
      );
    this.appState$.subscribe();
    this.changePageStyle();
  }

  async loadQuillModule(_editor: any) {
    if (this.platformService.getIsBrowser()) {
      const { Delta } = await import('quill/core');
      this.quill = _editor.editor;
      this.quill.clipboard.addMatcher(
        Node.ELEMENT_NODE,
        function (node: HTMLElement) {
          return new Delta().insert(node.innerText);
        },
      );
      const Block = this.editorRef.dynamicQuill.import('blots/block');
      const Inline = this.editorRef.dynamicQuill.import('blots/inline');
      Block.tagName = 'DIV';
      Inline.tagName = 'SPAN';
      this.editorRef.dynamicQuill.register(Block, true);
      this.editorRef.dynamicQuill.register(Inline, true);
      quillImageHandler(this.editorRef.dynamicQuill, this.quill);
      quillMapHandler(this.editorRef.dynamicQuill);
    }
  }

  onEditorInit(e: any) {
    this.loadQuillModule(e);
  }

  ngOnDestroy(): void {
    console.log('onDestroy');
    this.changePageStyle(!0);
  }

  fileChangeEvent(event: any): void {
    if (event.target.files.length) {
      this.file = event.target.files[0];
      validateImage(this.file)
        .then(() => {
          this.imageChangedEvent = event;
          this.visible = true;
        })
        .catch((err) => {
          this.messageService.add(err);
        });
    }
  }

  editAndRemoveCroppedImage(action: string) {
    action === 'E' && (this.visible = true);
    action === 'D' &&
      (!this.draftId
        ? ((this.croppedImage = null), (this.hasCoverImage = !1))
        : this.deleteImage());
  }

  onTieredMenuItemClick(action: string) {
    switch (action) {
      case 'image_delete':
        this.editAndRemoveCroppedImage('D');
        break;
      case 'image_upload':
        this.uploadRef.nativeElement.click();
        break;
      case 'manage':
        this.discardDraft();
    }
  }

  onCropperClose() {
    this.visible = false;
    this.croppedImage = !!this.croppedImage;
  }

  onCropped() {
    if (!this.draftId) {
      this.visible = false;
      this.hasCoverImage = !0;
      this.croppedImage = this.sourceImage;
    } else {
      this.hasCoverImage = !1;
      this.uploadImage(Number(this.draftId));
    }
  }

  onCropperDialogShow() {
    this.scale = 1;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.blob = event?.blob;
    this.cropperPosition = event.cropperPosition;
    this.sourceImage =
      event.base64 ||
      this.sanitizer.bypassSecurityTrustUrl(event.objectUrl as string);
  }

  zoomInAndOut(transform: string) {
    if (transform === 'in') this.scale += 0.1;
    else this.scale -= 0.1;
    this.transform = {
      ...this.transform,
      scale: this.scale,
    };
  }

  updateInspiration() {
    this.isPageLoading = true;
    this.inspirationService
      .updateDraft(this.draftId, {
        title: this.title,
        content: this.editorRef.getQuill().root.innerHTML,
        publisherId: this.publishas?.id || null,
        publicationId: this.publicationto.id || null,
        coverImageCaption: this.coverPhotoCaption,
      })
      .subscribe({
        next: (data: any) => {
          this.router.navigate(['/inspiration', 'preview', data.id], {
            state: data,
          });
        },
        error: (err) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Inspiration',
            detail: err.error.message,
          });
          this.isPageLoading = false;
        },
        complete: () => {
          this.isPageLoading = false;
        },
      });
  }

  onSubmit() {
    if (this.draftId) {
      this.updateInspiration();
    }
  }

  getDraftedInspiration(id: string) {
    this.isPageLoading = true;
    this.inspirationService.getDraftById(id).subscribe({
      next: (data: any) => {
        this.draft = data;
        this.title = this.draft.title;
        this.coverPhotoCaption = this.draft.coverImageCaption;
        const domParser = new DOMParser();
        const parsedHTML = domParser.parseFromString(
          this.draft.content,
          'text/html',
        );
        const nodes = Array.from(parsedHTML.body.children);
        const mapElements = parsedHTML.querySelectorAll('.mp-map');
        for (let node = 0; node < nodes.length; node++) {
          const _node: any = nodes[node];
          const range = this.quill.getSelection(true);
          if (
            _node.hasAttribute('class') &&
            _node.classList.contains('slider')
          ) {
            const hasThumbnail = _node.querySelector('.quill-thumbnail');
            const images = _node?.firstElementChild?.querySelectorAll('img');
            let _galleryName = _node.getAttribute('data-gallery') as string;
            this.quillImages = [];
            this.selectImageType = hasThumbnail ? ImageGrid.M : ImageGrid.S;
            images?.forEach((img: HTMLImageElement) => {
              this.quillImages.push({
                id: 0,
                fileName: '',
                filePath: '',
                fileURL: img.src,
              });
            });
            _galleryName = _galleryName?.split(' ')?.join('_');
            this.galleryNameKey = _galleryName;
            this.galleries[_galleryName] = this.quillImages;
            this.insertEmbed();
          } else {
            this.quill.insertEmbed(
              range.index,
              'block',
              '',
              this.editorRef.dynamicQuill.sources.SILENT,
            );
            this.quill.setSelection(
              range.index + 1,
              this.editorRef.dynamicQuill.sources.SILENT,
            );
            const b: any = this.document.querySelector('.ql-editor');
            b.children[node].appendChild(_node);
          }
        }
        this.getPublisher(this.draft.publisher.id);
        this.renderMap(mapElements);
        this.hasCoverImage = data.coverImageId;
      },
      error: (err) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Inspiration',
          detail: err.error.message,
        });
      },
      complete: () => {
        this.isPageLoading = false;
      },
    });
  }

  renderMap(mapNodes: any) {
    mapNodes.forEach((m: Element) => {
      const { sd, wp } = JSON.parse(
        m.getAttribute('data-map-locations') as string,
      );
      initMap(m.firstElementChild?.id as string, [], m.firstElementChild).then(
        (mapInstance: any) => {
          if (wp) {
            this.calculateAndDisplayRoute(
              sd.source,
              sd.destination !== '' ? sd.destination : sd.source,
              mapInstance,
              wp,
              !0,
            );
          } else {
            setAdvancedMarker(mapInstance, sd.latlng, sd.source, !1);
            setMapCenter(mapInstance, sd.latlng);
            setMapZoom(mapInstance, 10);
          }
        },
      );
    });
  }

  quillFileChangeEvent(event: any): void {
    const files = event.target.files;
    const fm = new Map<string, File>();
    this.galleryNameKey = this.galleryName.split(' ').join('_');

    if (this.selectImageType === ImageGrid.S) {
      this.quillImages = [];
    }

    if (files.length) {
      for (const file of files) {
        fm.set(file.name, file);
        this.quillImages.push({
          id: 0,
          filePath: '',
          fileName: file.name,
          fileURL: URL.createObjectURL(new Blob([file], { type: file.name })),
        });
      }

      if (!this.filesMap?.size) {
        this.filesMap = fm;
      } else {
        for (const [k, v] of fm.entries()) {
          this.filesMap.set(k, v);
        }
      }

      if (this.selectImageType === ImageGrid.M) {
        this.galleries[this.galleryNameKey] = this.quillImages;
      }
    }
  }

  insertEmbed() {
    const range = this.quill.getSelection(true);
    let sliderIndex = 0;
    let sliders = null;
    if (!this.isGalleryEdit) {
      this.quill.insertEmbed(
        range.index,
        'mp-image-gallery',
        ``,
        this.editorRef.dynamicQuill.sources.SILENT,
      );

      this.quill.setSelection(
        range.index + 1,
        this.editorRef.dynamicQuill.sources.SILENT,
      );
    }

    sliders = !this.isGalleryEdit
      ? Array.from(this.document.querySelectorAll('.slider'))
      : [this.updateEmbed()];

    for (const slider of sliders) {
      if (slider?.hasAttribute('data-quill-slider')) {
        continue;
      }
      const last = slider?.lastElementChild;
      const thumbnail = slider?.querySelector('.keen-slider-thumbnail');
      thumbnail && ImageGrid.S === this.selectImageType && thumbnail.remove();
      ImageGrid.S === this.selectImageType &&
        slider?.firstElementChild?.setAttribute(
          'style',
          'flex-direction:column;gap:4px;',
        );

      for (const img of this.quillImages) {
        slider?.firstElementChild?.insertAdjacentHTML(
          'afterbegin',
          `<div class="keen-slider__slide" style="aspect-ratio:16 / 9;background:${this.isDark ? 'rgba(1, 73, 94, 0.4)' : 'rgb(217, 251, 246)'};"><img src="${img.fileURL}" data-caption="${img.fileName}" style="width:100%;height:100%;object-fit:contain;object-position:center;" alt="" /></div>${this.selectImageType === ImageGrid.S ? '<span style="display:block;text-align:right;color:#868686;font-style:italic;">(' + this.caption + ')</span>' : ''}`,
        );
        if (ImageGrid.M === this.selectImageType) {
          const keenEl: any = last?.childNodes[1];
          keenEl?.insertAdjacentHTML(
            'afterbegin',
            `<div class="keen-slider__slide" style='cursor:pointer;aspect-ratio:16 / 9;background:${this.isDark ? 'rgba(1, 73, 94, 0.4)' : 'rgb(217, 251, 246)'};${sliderIndex === this.quillImages.length - 1 ? 'opacity:1;' : 'opacity:0.8'}'><img src="${img.fileURL}" data-caption="${img.fileName}" style="width:100%;height:100%;object-fit:contain;object-position:center;" alt="" /></div>`,
          );
        }
        sliderIndex++;
      }
      slider?.setAttribute('data-quill-slider', 'true');
      if (ImageGrid.S !== this.selectImageType) {
        quillImageSlider(slider?.firstElementChild, last);
        slider?.insertAdjacentHTML(
          'beforeend',
          `<div style="display:flex;justify-content:space-between;" id="gallery-name"><span>${this.galleryName}</span><span style="color:#868686;font-style:italic;">(${this.caption})</span></div>
          `,
        );
        slider?.insertAdjacentHTML(
          'beforeend',
          `<button type="button" style="background-color:transparent;position:absolute;top:5px;right:10px;width:2rem;height:2rem;display:flex;justify-content:center;align-items:center;" id="more-btn">
            <span class="material-icons" style='cursor:pointer;background:#ececece8;border-radius:50%'>more_horiz</span>
            <ul class="quill-image-dropdown" style="display:flex;width:150px;" data-gallery-ref="${this.galleryNameKey}">
              <li class="active"><a href="#">Edit</a></li>
            </ul>
          </button>
          `,
        );
        slider?.setAttribute('data-gallery', this.galleryNameKey);
      }
      const _more = slider?.querySelector('#more-btn');
      const imageDD = slider?.querySelector('.quill-image-dropdown');
      _more?.addEventListener('click', (e: any) => {
        const quillMenu = e.currentTarget.lastElementChild;
        quillMenu && quillMenu.classList.toggle('quill-image-dropdown__active');
      });
      imageDD?.addEventListener('click', (e: any) => {
        const t = e.target;
        const { galleryRef } = e.currentTarget.dataset;
        const tags = ['A', 'LI'];
        if (tags.includes(t.nodeName)) {
          this.selectImageType = ImageGrid.M;
          this.quillImages = this.galleries[galleryRef];
          this.galleryName = galleryRef.split('_').join(' ');
          this.galleryNameKey = galleryRef;
          this.isGalleryEdit = !0;
          this.quillImageVisible = !0;
        }
      });

      const thumbnails = slider?.querySelector('.quill-thumbnail');
      const keenThumbnail = slider?.querySelector(
        '.keen-slider-thumbnail',
      ) as HTMLDivElement;
      if (thumbnails) {
        thumbnails.setAttribute(
          'style',
          thumbnails.childNodes.length > 3
            ? 'justify-content:normal;'
            : 'justify-content:center',
        );
      }
      if (keenThumbnail) {
        keenThumbnail.style.background = this.isDark
          ? 'rgb(13 31 56)'
          : 'rgb(164 227 218)';
      }
    }
    this.quillImageVisible = false;
  }

  insert() {
    if (this.galleryName === '' && this.selectImageType === ImageGrid.M) {
      this.hasGalleryNameEmpty = !0;
      return;
    }

    this.hasGalleryNameEmpty = !1;
    if (
      (!this.filesMap || this.filesMap.size <= 1) &&
      this.selectImageType === ImageGrid.M
    ) {
      this.messageService.add({
        severity: 'error',
        summary: 'Images',
        detail: 'Minimum 2 images required',
      });
      return;
    }

    if (this.filesMap.size > 0) {
      const formData = new FormData();
      const itr = this.filesMap.values();
      let v;
      formData.append('images', itr.next().value);
      while ((v = itr.next())) {
        if (v.done) break;
        formData.append('images', v.value);
      }
      this.uploadImages(formData);
    }
  }

  onGalleryNameChange(e: any) {
    if (e !== '') this.hasGalleryNameEmpty = !1;
    else this.hasGalleryNameEmpty = !0;
  }

  async uploadImages(formData: FormData) {
    this.isLoading = !0;
    const _images = formData.getAll('images') as File[];
    let _compressedFormData: any = [];
    const _formData = new FormData();
    let _file = null;

    for (const f of _images) {
      console.log(`Before Compress (${f.name}): ${f.size} ${f.type}`);
      _compressedFormData.push(
        this.userService.compressImage(new Blob([f], { type: f.type })),
      );
    }

    _compressedFormData = await Promise.all(_compressedFormData);

    for (let _c = 0; _c < _compressedFormData.length; _c++) {
      _file = _compressedFormData[_c].get('file');
      const _newFile = new File([_file], _images[_c].name, {
        type: _images[_c].type,
        lastModified: _images[_c].lastModified,
      });
      _formData.append('images', _newFile);
    }

    this.inspirationService.uploadGalleryImages(_formData).subscribe({
      next: (data: any) => {
        if (this.isGalleryEdit) {
          const _filteredImages = this.quillImages.filter((g) => g.id !== 0);
          this.quillImages = [..._filteredImages, ...data];
        } else {
          this.quillImages = data;
        }
        this.galleries[this.galleryName] = this.quillImages;
        this.insertEmbed();
        this.isLoading = false;
      },
      error: (err) => {
        const { errors = null } = err?.error || {};
        this.messageService.add({
          severity: 'error',
          summary: 'Create Inspiration',
          detail: errors ? errors[0] : 'Something went wrong',
        });
        this.isLoading = false;
      },
    });
  }

  async uploadImage(id: number) {
    const formData = await this.userService.compressImage(this.blob);
    if (this.blob) {
      this.isLoading = !0;
      this.inspirationService.uploadInspirationCover(id, formData).subscribe({
        next: (data: any) => {
          console.log('Request to upload cover photo succeeded.');
          this.isLoading = false;
          this.hasCoverImage = !0;
          if (data.error) {
            this.messageService.add({
              severity: 'error',
              summary: 'Inspiration',
              detail: data.message,
            });
          } else {
            this.visible = false;
          }
        },
        error: (err) => {
          this.isLoading = false;
          console.error(
            'Request to upload cover photo errored out.' + err.message,
          );
          this.messageService.add({
            severity: 'error',
            summary: 'Inspiration',
            detail: err.error.message,
          });
        },
      });
    }
  }

  deleteImage() {
    this.isPageLoading = true;
    this.inspirationService
      .removeInspirationDraftCover(this.draftId)
      .subscribe({
        next: (data: any) => {
          this.messageService.add({
            severity: data.error ? 'error' : 'success',
            summary: 'Delete Cover Photo',
            detail: data.message,
          });
          this.hasCoverImage = false;
          this.coverPhotoCaption = '';
          this.isPageLoading = false;
        },
        error: (err) => {
          this.isPageLoading = false;
          this.messageService.add({
            severity: 'error',
            summary: 'Delete Cover Photo',
            detail: err.error.message,
          });
        },
      });
  }

  removeSliderImage(gallery: Galleries) {
    this.isLoading = true;
    this.inspirationService
      .deleteAlbumImages({ imageIds: [gallery.id] })
      .subscribe({
        next: (data: any) => {
          this.messageService.add({
            severity: data.error ? 'error' : 'success',
            summary: 'Delete Album Image',
            detail: 'Album image deleted successfully',
          });
          this.quillImages = this.quillImages.filter(
            (i) => i.fileURL !== gallery?.fileURL,
          );
          this.galleries[this.galleryName] = this.quillImages;
          this.isLoading = false;
        },
        error: (err) => {
          const { errors = null } = err?.error || {};
          this.messageService.add({
            severity: 'error',
            summary: 'Delete Image',
            detail: errors ? errors[0] : 'Something went wrong',
          });
          this.isLoading = false;
        },
      });
  }

  updateEmbed() {
    const galleryDOM = this.document.querySelector(
      `[data-gallery="${this.galleryNameKey}"]`,
    );
    if (galleryDOM) {
      const quillSlider = galleryDOM.querySelector('.quill-slider');
      const quillThumbnail = galleryDOM.querySelector('.quill-thumbnail');
      const moreBtn = galleryDOM.querySelector('#more-btn');
      const galleryEl = galleryDOM.querySelector('#gallery-name');
      quillSlider?.childNodes.forEach((node) => node.remove());
      quillThumbnail?.childNodes.forEach((node) => node.remove());
      galleryDOM.removeAttribute('data-quill-slider');
      moreBtn?.remove();
      galleryEl?.remove();
    }
    return galleryDOM;
  }

  dialogEvents(action: string, args?: Galleries) {
    if (action === 'R') {
      if (args?.id) {
        this.removeSliderImage(args);
      } else {
        this.quillImages = this.quillImages.filter(
          (i) => i.fileURL !== args?.fileURL,
        );
        this.galleries[this.galleryName] = this.quillImages;
        this.filesMap.delete(args?.fileName);
      }
    } else if (action === 'O') {
      this.quillImages = [];
      this.galleryName = '';
      this.galleryNameKey = '';
      this.isGalleryEdit = false;
      this.filesMap = null;
      this.caption = '';
      this.selectImageType = ImageGrid.S;
    } else if (action === 'M') {
      this.locations = [];
      this.map = null;
      this.directionRenderer = null;
      this.waypoints = [];
      this.selectMapType = MapType.P;
    }
  }

  discardDraft() {
    this.isPageLoading = true;
    this.inspirationService.discardDraft(this.draftId).subscribe({
      next: (data: any) => {
        this.isPageLoading = false;
        if (data.error) {
          this.messageService.add({
            severity: 'error',
            summary: 'Discard Draft',
            detail: data.message,
          });
        } else {
          this.router.navigate(['/inspiration', 'authored']);
        }
      },
      error: (err) => {
        const { errors = null } = err?.error || {};
        this.messageService.add({
          severity: 'error',
          summary: 'Discard Draft',
          detail: errors ? errors[0] : 'Something went wrong',
        });
        this.isPageLoading = false;
      },
    });
  }

  toggleMenu(e: any) {
    this.manageRef.toggle(e);
  }

  openMapDialog() {
    this.quillMapVisible = !0;
    this.addAndRemoveAddress('A');
  }

  onMapTypeChange(e: any) {
    this.locations = [];
    this.directionRenderer && this.directionRenderer.setMap(null);
    if ([MapType.L, MapType.R].includes(e)) {
      [0, 1].forEach(() => {
        this.addAndRemoveAddress('A');
      });
    } else {
      this.addAndRemoveAddress('A');
    }
  }

  onImageTypeChange(e: any) {
    this.quillImages = [];
    this.filesMap = null;
    this.caption = '';
  }

  addAndRemoveAddress(action: string, id?: string) {
    if (action === 'A') {
      const id = 'location_' + Math.floor(Math.random() * 1000);
      this.locations.push({
        id,
        error: false,
      });

      if (!this.map) {
        setTimeout(() => {
          this.addMapWrapperElement();
          initMap('quill-map__' + this.wrapperMapId, []).then((mapIns: any) => {
            mapIns &&
              setAdvancedMarker(
                mapIns as google.maps.Map,
                new google.maps.LatLng({ lat: 37.5124355, lng: -121.8908123 }),
              ).then(
                () =>
                  MapType.P !== this.selectMapType &&
                  (removeMarkers(), emptyMarkers()),
              ),
              (this.gmSearchBox(this.document.getElementById(id), mapIns),
              (this.map = mapIns));
          });
        }, 0);
      } else {
        setTimeout(() => {
          setAdvancedMarker(
            this.map as google.maps.Map,
            new google.maps.LatLng({ lat: 37.5124355, lng: -121.8908123 }),
          ).then(() => (removeMarkers(), emptyMarkers()));
          this.gmSearchBox(this.document.getElementById(id), this.map);
        }, 0);
      }
    } else if (action === 'D') {
      this.locations = this.locations.filter((location) => location.id !== id);
    }
  }

  addMapWrapperElement() {
    const wrapper = this.document.querySelector('.quill-map__wrapper');
    if (wrapper && !wrapper.firstElementChild) {
      this.wrapperMapId = 'location_' + Math.floor(Math.random() * 100);
      const quillMapDiv = this.document.createElement('div');
      quillMapDiv.id = 'quill-map__' + this.wrapperMapId;
      quillMapDiv.setAttribute('style', 'height:250px;margin-top: 8px;');
      wrapper.appendChild(quillMapDiv);
    } else if (
      wrapper &&
      wrapper?.firstElementChild &&
      wrapper.firstElementChild.id !== 'quill-map__location'
    ) {
      this.wrapperMapId = 'location_' + Math.floor(Math.random() * 100);
      wrapper.firstElementChild.id = 'quill-map__' + this.wrapperMapId;
    }
  }

  gmSearchBox(el: any, map: any) {
    const {
      places: { Autocomplete },
      LatLngBounds,
      LatLng,
    } = window.google.maps;
    const searchBox = new Autocomplete(el, {
      componentRestrictions: { country: 'us' },
    });
    const bounds = map.getBounds();
    map.addListener('bounds_changed', () => {
      searchBox.setBounds(bounds || null);
    });
    searchBox.addListener('place_changed', () => {
      const places = searchBox.getPlace() || [];
      const { geometry, formatted_address } = places;
      const bounds = new LatLngBounds();
      const latlng = new LatLng({
        lat: geometry?.location?.lat() as number,
        lng: geometry?.location?.lng() as number,
      });
      if (places.geometry?.viewport) {
        bounds.union(places.geometry.viewport);
      } else {
        bounds.extend(latlng);
      }
      map.fitBounds(bounds);
      this.latLng = latlng;
      this.setMapConfigs(map, latlng, formatted_address);
      this.sourceAndDestination.source = formatted_address as string;
      this.sourceAndDestination.latlng = latlng;
      this.sourceAndDestination.destination = '';

      if (this.validateLocationFields()) {
        return;
      }

      [MapType.R, MapType.L].includes(this.selectMapType) &&
        (this.onLocationChange(map), removeMarkers());
    });
  }

  insertMap() {
    const range = this.quill.getSelection(true);

    if (this.validateLocationFields()) {
      return;
    }

    this.quill.insertEmbed(
      range.index,
      'mp-map',
      ``,
      this.editorRef.dynamicQuill.sources.SILENT,
    );
    this.quill.setSelection(
      range.index + 1,
      this.editorRef.dynamicQuill.sources.SILENT,
    );
    const quillMap = this.document.getElementById(
      'quill-map__' + this.wrapperMapId,
    );
    const mpMap = this.document.querySelectorAll('.mp-map');

    if (quillMap && mpMap.length) {
      quillMap.id += '_inject';
      mpMap.forEach((node: Element) => {
        if (!node.hasAttribute('data-map')) {
          node.setAttribute('data-map', 'true');
          node.setAttribute(
            'data-map-locations',
            JSON.stringify({
              wp: this.waypoints,
              sd: this.sourceAndDestination,
            }),
          );
          node.appendChild(quillMap);
        }
      });
    }
    this.quillMapVisible = !1;
    emptyMarkers();
  }

  validateLocationFields() {
    const locationsEl = Array.from(
      this.document.querySelectorAll('[id^="location_"]'),
    );
    let inputEl;
    let hasErrors = !1;

    for (let node = 0; node < locationsEl.length; node++) {
      inputEl = locationsEl[node] as HTMLInputElement;
      if (this.locations[node].id === locationsEl[node].id) {
        if (inputEl.value === '') {
          this.locations[node].error = !0;
          hasErrors = !0;
        } else {
          this.locations[node].error = !1;
          hasErrors = !1;
        }
      }
    }
    return hasErrors;
  }

  setMapConfigs(
    map: google.maps.Map,
    latlng: google.maps.LatLng,
    title?: string | undefined,
  ) {
    this.selectMapType === MapType.P && setAdvancedMarker(map, latlng, title);
    setMapCenter(map, latlng);
    setMapZoom(map, 10);
  }

  onLocationChange(map: google.maps.Map) {
    const _s = this.document.getElementById(
      this.locations[0].id,
    ) as HTMLInputElement;
    const _e = this.document.getElementById(
      this.locations[this.locations.length - 1].id,
    ) as HTMLInputElement;
    let waypoints: google.maps.DirectionsWaypoint[] = [];

    waypoints = this.locations
      .slice(
        1,
        this.selectMapType === MapType.R
          ? this.locations.length - 1
          : this.locations.length,
      )
      .reduce((acc: any, curr: any) => {
        const _ways = this.document.getElementById(curr.id) as HTMLInputElement;
        if (_ways.value !== '') acc.push({ location: _ways?.value });
        return acc;
      }, []);
    if (_e.value !== '' || waypoints.length) {
      this.waypoints = waypoints;
      this.sourceAndDestination.source = _s.value;
      this.sourceAndDestination.destination =
        this.selectMapType === MapType.R ? _e.value : _s.value;
      this.calculateAndDisplayRoute(
        _s.value,
        this.selectMapType === MapType.R ? _e.value : _s.value,
        map,
        waypoints,
      );
    }
  }

  calculateAndDisplayRoute(
    startAddress: string,
    endAddress: string,
    map: google.maps.Map,
    waypoints: google.maps.DirectionsWaypoint[],
    newObject = false,
  ) {
    const directionsService = new google.maps.DirectionsService();
    let renderer: google.maps.DirectionsRenderer;

    if (!newObject) {
      if (!this.directionRenderer) {
        this.directionRenderer = new google.maps.DirectionsRenderer();
      }
      this.directionRenderer.setMap(map);
    } else {
      renderer = new google.maps.DirectionsRenderer();
      renderer.setMap(map);
    }

    directionsService
      .route({
        origin: startAddress,
        destination: endAddress,
        optimizeWaypoints: true,
        waypoints,
        travelMode: google.maps.TravelMode.DRIVING,
      })
      .then((response) => {
        if (!newObject)
          this.directionRenderer &&
            this.directionRenderer.setDirections(response);
        else renderer.setDirections(response);
      })
      .catch((r) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Route',
          detail: r.message,
        });
      });
  }

  changePageStyle(isDestroy = false) {
    const mpMainEl = this.document.querySelectorAll('.mp-main');
    if (mpMainEl.length) {
      mpMainEl.forEach((node: Element, index: number) => {
        if (!isDestroy) {
          index === 0 && node.setAttribute('style', 'height:unset;');
          index === 1 &&
            (node.setAttribute('style', 'overflow:auto;height:unset;'),
            this.document.body.setAttribute('style', 'overflow:hidden;'));
        } else {
          node.removeAttribute('style');
          this.document.body.removeAttribute('style');
        }
      });
    }
  }

  changeKeenSlideTheme(isDark: boolean) {
    const keenSliders = this.document.querySelectorAll('.keen-slider__slide');
    const keenThumbnail = this.document.querySelectorAll(
      '.keen-slider-thumbnail',
    );
    if (keenSliders.length && keenThumbnail.length) {
      keenSliders.forEach((slider: any) => {
        slider.style.background = isDark
          ? 'rgba(1, 73, 94, 0.4)'
          : 'rgb(217, 251, 246)';
      });
      keenThumbnail.forEach((thumbnail: any) => {
        thumbnail.style.background = isDark
          ? 'rgb(13 31 56)'
          : 'rgb(164 227 218)';
      });
    }
  }

  getInspirationDraftCoverImage(id: string | null) {
    return (
      environment.apiUrl + '/api/public/inspirations/draft/' + id + '/cover'
    );
  }

  seeMore(activeIndex: number) {
    this.dialogEvent.next({
      visible: true,
      dialogType: 'INSPIRATION',
      activeIndex,
      data: {
        publications: this.publications,
        publisher: this.publishers,
        selectedPublisher: this.publishas,
        selectedPublishAs: this.publicationto,
      },
    });
  }

  getPublisher(id: number) {
    this.inspirationService.getPublishers().subscribe({
      next: (data: any) => {
        this.publishers = data;
        this.publishas =
          this.publishers.find((p: Publisher) => p.id === id) || null;
        this.getPublication(this.publishas?.id);
      },
      error: (err) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Publisher',
          detail: err.error.message,
        });
        this.isPageLoading = false;
      },
    });
  }

  getPublication(id: number | undefined) {
    this.inspirationService.getPublications('' + id).subscribe({
      next: (data: any) => {
        this.publications = data;
        this.publicationto = this.publications[0];
      },
      error: (err) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Publication',
          detail: err.error.message,
        });
        this.isPageLoading = false;
      },
    });
  }

  publishData(e: any) {
    const latestPublishAsIndex = this.publishers.findIndex(
      (p) => p.id === e.publishAs.id,
    );
    const latestPublisToIndex = e.publications.findIndex(
      (p: any) => p.id === e.publishTo.id,
    );

    if (latestPublishAsIndex > -1) {
      this.publishers.unshift(this.publishers[latestPublishAsIndex]);
      this.publishers.splice(latestPublishAsIndex + 1, 1);
    }

    if (latestPublisToIndex > -1) {
      e.publications.unshift(e.publications[latestPublisToIndex]);
      e.publications.splice(latestPublisToIndex + 1, 1);
    }

    this.publishas = e.publishAs;
    this.publicationto = e.publishTo;
    this.publications = e.publications;
  }

  onPublisherChange(e: any) {
    this.getPublication(e.value.id);
  }

  onTextAreaChange(e: any) {
    this.textAreaRef.nativeElement.style.height = 'auto';
    this.textAreaRef.nativeElement.style.height =
      this.textAreaRef.nativeElement.scrollHeight + 'px';
  }
}
