<div class="surface-ground h-full">
  <div
    class="ng-mat-drawer-content surface-overlay pb-8 transition-all duration-300"
  >
    <div>
      <div
        class="linear max mx-auto mb-24 max-w-[1120px] transition-all duration-500"
      >
        <app-shared-inspiration-view
          [inspirationId]="inspirationId"
          (tableOfContent)="getView($event)"
          (categories)="getCategoriesAndActivities($event)"
          (activities)="getCategoriesAndActivities($event, !1)"
          styleClass="max-w-[1024px] mx-auto"
        ></app-shared-inspiration-view>
      </div>
    </div>
  </div>
</div>
